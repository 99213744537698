/* eslint-disable no-lone-blocks */
import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames/bind";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import ReactHtmlParser from "react-html-parser";
import InputRange from "react-input-range"; // input range 관련 패키지 받아옴
import "./inputrange.css"; // input range 관련 패키지 css
import Image from "components/common/Image";

import Popup from "components/common/Popup/Popup";
import Compare from "containers/house/Compare/CompareContainer";
import Login from "containers/user/login/UserLoginContainer";

import filter from "assets/images/filter.png";
import refresh from "assets/images/refesh.png";
import search_icon from "assets/images/Search.png";
import folder_add from "assets/images/folder_add.png";
import star from "assets/images/star_yellow.png";
import star2 from "assets/images/star2.png";
import vr from "assets/images/list_vr.png";
import folder_add_w from "assets/images/folder_add_w.png";
import remove from "assets/images/Remove.png";
import filter_vr from "assets/images/filter_vr.png";
import category2 from "assets/images/subway.png";
import category1 from "assets/images/school.png";
import category4 from "assets/images/house.png";
import category3 from "assets/images/pin.png";
import map_pin from "assets/images/pin_mint.png";
import vr_active from "assets/images/vr_active.png";
import rectangle255 from "assets/images/Rectangle255.png";
import arrow_up from "assets/images/arrow_up.png";
import arrow_down from "assets/images/arrow_down.png";

import styles from "./_Search.module.scss";
const cx = classnames.bind(styles);

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
    this.scrollRef = createRef();
    this.focusRef = createRef();
    this.state = {
      ROWS: 10,
      NORTH: 0,
      SOUTH: 0,
      EAST: 0,
      WEST: 0,
      deposit: { min: 0, max: 100000000 },
      monthly: { min: 0, max: 2000000 },
      ShowFlilter: false,
      Isfocus: false,
      NowSearchKeyword: "",
      NowSearchKeyHelper: [],
      SHAREHOUSE: "N", // 거래유형 쉐어하우스
      HOUSE2030: "N", // 거래유형 청년주택
      PREMIUM_HOUSE: "N", // 거래유형 원룸/투룸
      VR_HOUSE: "N", // vr하우스 보기
      NEW_OPEN: "N", // 입주조건 신규하우스
      NOW_MOVIN: "N", // 추가조건 즉시입주
      CAMPAIGN: "N", //이벤트중인 하우스
      GENDER_WOMAN: "N", //입주조건 여성전용
      GENDER_MAN: "N", //입주조건 남성전용
      GENDER_ALL: "N", // 입주조건 남여공통
      FOREIGNER: "N", //추가조건 외국인가능
      DEPOSIT_ADJUST: "N", //추가조건 보증금 조절
      FAT: "N", // 추가조건 반려동물가능
      isModalOpen: false,
      isLoginModalOpen: false,
      mobileHelp: false,
      searchUp: false,
      compareList: []
    };
  }

  // 컴포넌트 나갈시
  async componentWillUnmount() {
    // if (window.screen.width >= 768)
    //   document.getElementsByTagName("footer")[0].style.display = "block";
    // else document.getElementsByTagName("footer")[1].style.display = "block";

    // document.getElementById("mainTemplate_goPrev_btn").style.display = "block";

    window.removeEventListener("scroll", this.handleScroll);

    window.kakao.maps.event.removeListener(this.map, "idle");
    delete this.scrollRef;
    try {
      await window.ReactNativeWebView.postMessage("isMain|false");
    } catch (e) {}
  }

  componentDidMount() {
    // try {
    //   const { t } = this.props;
    //   window.ReactNativeWebView.postMessage(
    //     "onChangeTitle|" + t("header.searchhouse")
    //   );
    // } catch (error) {}
    // document.getElementById("mainTemplate_goPrev_btn").style.display = "none";

    this.PullSearchHelper = _.debounce(this.PullSearchHelper, 200, {
      maxWait: 500
    });

    window.addEventListener("scroll", this.handleScroll);

    //지도 생성 및 객체 리턴
    const options = {
      center: new window.kakao.maps.LatLng(37.5388383, 126.987206),
      level: 5,
      position: new window.kakao.maps.LatLng(37.5388383, 126.987206),
      draggable: true
    };

    this.map = new window.kakao.maps.Map(this.mapRef, options);

    this.map.setMaxLevel(7);

    let nextState = this.state;

    // 검색키워드 저장 되어 있을시 불러오기
    const MyStorage = localStorage;
    let searchWord = MyStorage.getItem("searchWord");
    if (searchWord) {
      nextState["NowSearchKeyword"] = searchWord;
    }

    // 메인에서 검색한 키워드가 있는지 없는지 검사
    if (this.props.location.state) {
      nextState["NowSearchKeyword"] = this.props.location.state.keyword;

      if (
        this.props.location.state.filter !== undefined &&
        this.props.location.state.filter !== null
      ) {
        this.props.location.state.filter.forEach(item => {
          if (item === "SHAREHOUSE") {
            nextState["SHAREHOUSE"] = "Y";
          }
          if (item === "HOUSE2030") {
            nextState["HOUSE2030"] = "Y";
          }
          if (item === "PREMIUM_HOUSE") {
            nextState["PREMIUM_HOUSE"] = "Y";
          }
        });
      }

      this.setState(nextState);
    }

    this.props
      .SearchNomination(nextState["NowSearchKeyword"])
      .then(value => {
        if (value === undefined || value === null) return null;
        let nextState = this.state;
        nextState["NowSearchKeyHelper"] = value;
        this.setState(nextState);
      })
      .then(() => {
        this.SearchKeyword(0);
        this.setBoundsEvent();
      });

    // 푸터 제거
    // document.getElementsByTagName("footer")[0].style.display = "none";
    // document.getElementsByTagName("footer")[1].style.display = "none";

    // 마커 클러스터러를 생성
    this.clusterer = new window.kakao.maps.MarkerClusterer({
      map: this.map,
      averageCenter: true,
      minLevel: 4,
      minClusterSize: 5
    });

    // 오버레이를 생성
    this.overlay = new window.kakao.maps.CustomOverlay({
      map: this.map,
      position: null,
      content: "",
      xAnchor: 0.5,
      yAnchor: 1.6
    });

    // 비교함 가져오는 함수
    this.getCompareList();
  }

  // 스크롤시 페이지처리
  handleScroll = () => {
    const clientHeight = this.scrollRef.current.clientHeight;
    const scrollTop = this.scrollRef.current.scrollTop;

    const scrollHeight = this.scrollRef.current.scrollHeight;

    if (window.screen.width > 768) {
      window.scrollTo();
    }

    if (scrollTop + clientHeight === scrollHeight) {
      if (
        this.props.houseList === null ||
        this.props.houseList === undefined ||
        this.props.houseList.length < this.state.rows
      )
        return;

      let nextState = this.state;

      nextState["ROWS"] = nextState["ROWS"] + 10;

      this.setState(nextState);
    }
  };

  // 모달
  openModal = e => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    let nextState = this.state;
    nextState["isModalOpen"] = true;
    this.setState(nextState);
    // this.setState({ isModalOpen : true });
  };

  closeModal = () => {
    this.getCompareList();
    this.setState({ isModalOpen: false });
  };

  loginOpenModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  loginCloseModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  // 키워드 색
  highlightkeyword = keyword => {
    let nextState = this.state;
    let rt_keyword = nextState["NowSearchKeyword"];
    let findexof = keyword.indexOf(rt_keyword);
    let lindexof = keyword.lastIndexOf(rt_keyword);
    let rt_stringlen = rt_keyword.length;
    let stringlen = keyword.length;
    let fsubstring = keyword.substring(0, findexof);
    let lsubstring = keyword.substring(lindexof + rt_stringlen, stringlen);

    return (
      fsubstring +
      `<span style="color:#16BDC6;">${rt_keyword}</span>` +
      lsubstring
    );
  };

  // 필터부분
  Residency = stateid => {
    let NextState = this.state;
    if (NextState[stateid] === "Y") {
      NextState[stateid] = "N";
    } else if (NextState[stateid] === "N") {
      NextState[stateid] = "Y";
    }

    this.setState(NextState);
    this.SearchKeyword();
  };

  // 맵 드래그해서 좌표 옮겼을떄
  setBoundsEvent = () => {
    window.kakao.maps.event.addListener(
      this.map,
      "idle",
      () => {
        if (this.scrollRef && this.scrollRef.current)
          this.scrollRef.current.scroll(0, 0);
        let nextState = this.state;

        nextState["ShowFlilter"] = false;
        nextState["ROWS"] = 10;

        this.setState(nextState);

        // Isfocus false
        this.SearchHelp("Blur");
        this.SearchKeyword();
      } // 주변 하우스 검색함
    );
  };

  // 필터버튼 눌러서 나오게함
  filter_toggle = () => {
    let nextState = this.state;
    nextState["mobileHelp"] = false;
    nextState["Isfocus"] = false;
    nextState["ShowFlilter"] = !this.state.ShowFlilter;
    this.searchUp.style.bottom = "-280px";
    nextState["searchUp"] = false;
    this.setState(nextState);
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // 포커스 들어오면 검색창 자동완성 나오게 함
  SearchHelp = type => {
    setTimeout(() => {
      let nextState = this.state;
      if (window.screen.width > 768) {
        if (type === "Focus") {
          nextState["Isfocus"] = true;
          nextState["ShowFlilter"] = false;
        } else {
          nextState["Isfocus"] = false;
          nextState["ShowFlilter"] = false;
        }
      } else {
        if (type === "Focus") {
          nextState["Isfocus"] = true;
          nextState["ShowFlilter"] = false;
          nextState["mobileHelp"] = true;
        } else {
          // nextState["Isfocus"] = false;
          // nextState["ShowFlilter"] = false;
        }
      }
      // nextState['ShowFlilter'] = false;
      this.searchUp.style.bottom = "-280px";
      nextState["searchUp"] = false;
      this.setState(nextState);
    }, 200);
  };

  onChange = e => {
    const { t } = this.props;
    if (this.state.Isfocus === false) {
      this.setState({ Isfocus: true });
    }

    let value = e.target.value;

    // eslint-disable-next-line
    const regexp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    if (regexp.test(value)) {
      // alert(t("search.searchAlert"));
      window.ReactNativeWebView.postMessage(
        "sendAlert|" + t("search.searchAlert")
      );
      return;
    }

    this.setState({
      NowSearchKeyword: value
    });

    this.PullSearchHelper();
  };

  PullSearchHelper = e => {
    if (
      this.state.NowSearchKeyword === undefined ||
      this.state.NowSearchKeyword === null ||
      this.state.NowSearchKeyword === "" ||
      this.state.NowSearchKeyword === " "
    ) {
      this.setState({ NowSearchKeyHelper: [] });
      return;
    }

    // eslint-disable-next-line
    // const regexp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    // if (regexp.test(this.state.NowSearchKeyHelper)) {
    //   this.setState({ NowSearchKeyHelper: [] });
    //   return;
    // }

    this.props.SearchNomination(this.state.NowSearchKeyword).then(value => {
      this.setState({
        ...this.state,
        NowSearchKeyHelper: value,
        mobileHelp: false
      });
    });
  };

  SearchKeyword = async key => {
    let nextState = this.state;

    if (key !== undefined && key !== null && key !== "") {
      const array = nextState["NowSearchKeyHelper"][key];

      if (array !== undefined && array !== null && array !== "") {
        nextState["NowSearchKeyword"] = array.KEYWORD;
        nextState["NowSearchKeyHelper"] = [];

        this.setState(nextState);

        const juso = new window.kakao.maps.LatLng(
          array["LATITUDE"],
          array["LONGITUDE"]
        );

        this.map.setCenter(juso);
      }
    }

    const bounds = this.map.getBounds();
    const swLatLng = bounds.getSouthWest();
    const neLatLng = bounds.getNorthEast();

    // 영역정보를 문자열로 얻어옵니다. ((남,서), (북,동)) 형식입니다
    nextState["SOUTH"] = swLatLng.getLat();
    nextState["WEST"] = swLatLng.getLng();
    nextState["NORTH"] = neLatLng.getLat();
    nextState["EAST"] = neLatLng.getLng();
    nextState["Isfocus"] = false;

    this.setState(nextState);

    const { SearchList } = this.props;

    const {
      NORTH,
      SOUTH,
      EAST,
      WEST,
      SHAREHOUSE,
      HOUSE2030,
      PREMIUM_HOUSE,
      VR_HOUSE,
      NEW_OPEN,
      NOW_MOVIN,
      CAMPAIGN,
      GENDER_WOMAN,
      GENDER_MAN,
      GENDER_ALL,
      FOREIGNER,
      DEPOSIT_ADJUST,
      FAT,
      deposit,
      monthly
    } = this.state;

    await SearchList({
      NORTH,
      SOUTH,
      EAST,
      WEST,
      SHAREHOUSE,
      HOUSE2030,
      PREMIUM_HOUSE,
      VR_HOUSE,
      NEW_OPEN,
      NOW_MOVIN,
      CAMPAIGN,
      GENDER_WOMAN,
      GENDER_MAN,
      GENDER_ALL,
      FOREIGNER,
      DEPOSIT_ADJUST,
      FAT,
      DEPOSIT_MIN: deposit.min,
      DEPOSIT_MAX: deposit.max !== 100000000 ? deposit.max : "",
      RENTFEE_MIN: monthly.min,
      RENTFEE_MAX: monthly.max !== 2000000 ? monthly.max : ""
    });

    const { houseLoad, houseList } = this.props;

    if (!houseLoad) {
      let marker = [];

      // 마커 이미지
      const markerImage = new window.kakao.maps.MarkerImage(
        map_pin,
        new window.kakao.maps.Size(31, 35),
        new window.kakao.maps.Point(13, 34)
      );

      houseList.forEach(item => {
        if (item === null || "") return null;
        marker.push({
          item: item,
          title: item["HOUSE_NAME"],
          url: item["HOUSE_URL"],
          latlng: new window.kakao.maps.LatLng(
            item["LATITUDE"],
            item["LONGITUDE"]
          )
        });
      });

      // 마커
      const markers = marker.map(position => {
        let tmp = new window.kakao.maps.Marker({
          position: position["latlng"],
          image: markerImage
        });

        const marker_position = position["latlng"];

        window.kakao.maps.event.addListener(
          tmp,
          "mouseover",
          this.makeOverlay.bind(this, marker_position, position["item"])
        );

        window.kakao.maps.event.addListener(
          tmp,
          "mouseout",
          this.makeOverlay.bind(this, null, "")
        );

        // TODO: 마커 클릭시 새창띄우기
        window.kakao.maps.event.addListener(tmp, "click", function() {
          window.open("/app" + position.url, "_blank");
        });

        return tmp;
      });

      const styles = [
        {
          width: "60px",
          height: "60px",
          "border-radius": "30px",
          "text-align": "center",
          color: "white",
          "line-height": "60px",
          background: "#8E8E8E"
        }
      ];

      this.clusterer.clear();
      this.clusterer.setStyles(styles); // 클러스터 스타일
      this.clusterer.addMarkers(markers); // 클러스터러에 마커들을 추가합니다
    }
  };

  // 오버레이 이벤트
  makeOverlay = (position, item) => {
    if (!item || !position) return this.overlay.setVisible(false);

    const content = `
        <div style="width:280px; height: 80px; background: #fff; border: 1px solid #BDBDBD; border-radius: 2px; box-sizing: border-box; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
                <div style="width: 80px; height: 80px; float: left;">
                    <img style="width:100%; height: 100%;" src="${item.INFO_THUMB_URL}" alt="숙소이미지"/>
                </div>
                <div style="width: 198px; height: 78px; padding-left: 15px; text-align: left; float: right;">
                    <div style="height: 45px; line-height: 45px; font-size: 14px; font-weight: 500;">${item.HOUSE_NAME}</div>
                    <div style="height: 33px; color: #828282; font-size: 12px; font-weight: 300;">${item.ADDRESS}</div>
                <div/>
        </div>`;

    this.overlay.setVisible(true);
    this.overlay.setContent(content);
    this.overlay.setPosition(position);
  };

  // 검색창에 값있으면 검색창 내용 한번에 지워주는 버튼 나오게함
  ResetShow = e => {
    let NextValue = e.target.value;
    let reset_btn = document.getElementsByClassName(cx("search-clear"));
    if (NextValue !== "") {
      reset_btn[0].style.display = "flex";
    } else {
      reset_btn[0].style.display = "none";
    }
  };

  // 검색에서 Enter시 첫번째꺼
  onSumbmit = e => {
    e.preventDefault();

    if (this.state.Isfocus === false) return null;

    const { NowSearchKeyHelper } = this.state;

    if (NowSearchKeyHelper.length > 0) {
      this.SearchKeyword(0);
    }

    // IsFocus false
    this.SearchHelp("Blur");
  };

  // 필터 초기화
  Reset = () => {
    let nextState = this.state;

    nextState["SHAREHOUSE"] = "N";
    nextState["HOUSE2030"] = "N";
    nextState["PREMIUM_HOUSE"] = "N";
    nextState["VR_HOUSE"] = "N";
    nextState["NEW_OPEN"] = "N";
    nextState["NOW_MOVIN"] = "N";
    nextState["CAMPAIGN"] = "N";
    nextState["GENDER_WOMAN"] = "N";
    nextState["GENDER_MAN"] = "N";
    nextState["GENDER_ALL"] = "N";
    nextState["FOREIGNER"] = "N";
    nextState["DEPOSIT_ADJUST"] = "N";
    nextState["FAT"] = "N";

    nextState["deposit"] = { min: 0, max: 100000000 };
    nextState["monthly"] = { min: 0, max: 2000000 };

    this.setState(nextState);
  };

  // 비교함 갯수 get
  getCompareList = () => {
    const MyStorage = localStorage;
    let nextState = this.state;
    let compareList_arr = JSON.parse(MyStorage.getItem("compared"));
    nextState["compareList"] = compareList_arr;
    this.setState(nextState);
  };

  // 비교함 추가
  AddCompareList = (e, HOUSE_ID) => {
    e.preventDefault();

    if (HOUSE_ID) {
      this.props.AddCompare(HOUSE_ID);
      this.getCompareList();
    }

    return false;
  };

  favorite = async (e, type, houseId) => {
    e.preventDefault();
    if (!this.props.isLogined) {
      this.loginOpenModal();
      return;
    }

    const { addFavHouse, delFavHouse } = this.props;

    if (type === "Y") {
      await delFavHouse(houseId);
    } else {
      await addFavHouse(houseId);
    }

    await this.SearchKeyword();

    return null;
  };

  // 서치 결과 스크롤 업
  searchScroll = e => {
    if (e.target === this.compareBtn) {
      return false;
    }
    const thisBottom = this.searchUp.style.bottom;
    let nextState = this.state;

    if (thisBottom === "" || thisBottom === "-280px") {
      this.searchUp.style.bottom = "-40px";
      nextState["searchUp"] = true;
    } else if (thisBottom === "-40px" || thisBottom === "-40px") {
      this.searchUp.style.bottom = "-280px";
      nextState["searchUp"] = false;
    }
    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    const { NowSearchKeyHelper } = this.state;
    return (
      <div className={cx("search-wrap")}>
        <div className={cx("search-bar")}>
          <form onSubmit={this.onSumbmit}>
            <label className={cx("search")}>
              <input
                className={cx("search-input")}
                type="text"
                placeholder={t("common.location2")}
                onFocus={() => this.SearchHelp("Focus")}
                onBlur={() => this.SearchHelp("Blur")}
                onClick={e => {
                  this.SearchHelp("Focus");
                  this.onChange(e);
                }}
                onChange={this.onChange}
                value={this.state.NowSearchKeyword}
              />
              <div>
                <button>
                  <img src={search_icon} alt="filter" />
                </button>
              </div>
              <div
                className={cx("search-clear")}
                onClick={e => this.reseter(e, "search-input")}
              >
                <img src={remove} alt="remove" />
              </div>
            </label>
            <label className={cx("filter-bar")} onClick={this.filter_toggle}>
              {this.state.SHAREHOUSE !== "Y" &&
                this.state.HOUSE2030 !== "Y" &&
                this.state.PREMIUM_HOUSE !== "Y" &&
                this.state.VR_HOUSE !== "Y" &&
                this.state.NEW_OPEN !== "Y" &&
                this.state.NOW_MOVIN !== "Y" &&
                this.state.GENDER_WOMAN !== "Y" &&
                this.state.GENDER_MAN !== "Y" &&
                this.state.GENDER_ALL !== "Y" &&
                this.state.FOREIGNER !== "Y" &&
                this.state.DEPOSIT_ADJUST !== "Y" &&
                this.state.FAT !== "Y" &&
                t("search.set")}

              {this.state.SHAREHOUSE === "Y" && (
                <span className={cx("filter-tag")}>
                  <input
                    type="text"
                    readOnly
                    defaultValue={t("search.sharehouse")}
                  />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("SHAREHOUSE")}
                  />
                </span>
              )}

              {this.state.HOUSE2030 === "Y" && (
                <span className={cx("filter-tag")}>
                  <input
                    type="text"
                    readOnly
                    defaultValue={t("search.youth")}
                  />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("HOUSE2030")}
                  />
                </span>
              )}

              {this.state.PREMIUM_HOUSE === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={t("search.room")} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("PREMIUM_HOUSE")}
                  />
                </span>
              )}

              {this.state.VR_HOUSE === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"VR"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("VR_HOUSE")}
                  />
                </span>
              )}

              {this.state.NEW_OPEN === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"신규오픈"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("NEW_OPEN")}
                  />
                </span>
              )}

              {this.state.NOW_MOVIN === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"즉시입주"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("NOW_MOVIN")}
                  />
                </span>
              )}

              {this.state.GENDER_WOMAN === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"여성전용"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("GENDER_WOMAN")}
                  />
                </span>
              )}

              {this.state.GENDER_MAN === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"남성전용"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("GENDER_MAN")}
                  />
                </span>
              )}

              {this.state.GENDER_ALL === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"남녀공통"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("GENDER_ALL")}
                  />
                </span>
              )}

              {this.state.FOREIGNER === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"외국인가능"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("FOREIGNER")}
                  />
                </span>
              )}

              {this.state.DEPOSIT_ADJUST === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"보증금조절"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("DEPOSIT_ADJUST")}
                  />
                </span>
              )}

              {this.state.FAT === "Y" && (
                <span className={cx("filter-tag")}>
                  <input type="text" readOnly defaultValue={"반려동물"} />
                  <img
                    src={remove}
                    alt="remove"
                    onClick={() => this.Residency("FAT")}
                  />
                </span>
              )}

              <div onClick={this.Reset}>
                <img src={refresh} alt="filter" />
                {t("search.reset")}
              </div>
            </label>
            <label className={cx("filter-push")} onClick={this.filter_toggle}>
              <div>
                <img src={filter} alt="filter" />
                <span>{t("search.filter")}</span>
              </div>
            </label>
            <div className={cx("clear")}></div>
          </form>
        </div>
        <div
          className={cx("filter-list")}
          data-filter_toggle={this.state.ShowFlilter}
        >
          <div>
            <div className={cx("filter-list-left")}>
              <div className={cx("vrhouse")}>
                <h3>{t("search.vr")}</h3>
                {this.state.VR_HOUSE === "Y" ? (
                  <div
                    style={{ background: "#16BDC6" }}
                    onClick={() => this.Residency("VR_HOUSE")}
                  >
                    <img src={vr_active} alt="vr" />
                  </div>
                ) : (
                  <div onClick={() => this.Residency("VR_HOUSE")}>
                    <img src={filter_vr} alt="vr" />
                  </div>
                )}
              </div>
              <div className={cx("trade-type")}>
                <h3>{t("search.trade")}</h3>
                <div>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.SHAREHOUSE === "Y" && true}
                      onClick={() => this.Residency("SHAREHOUSE")}
                    />
                    <div>{t("search.sharehouse")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.PREMIUM_HOUSE === "Y" && true}
                      onClick={() => this.Residency("PREMIUM_HOUSE")}
                    />
                    <div>{t("search.room")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.HOUSE2030 === "Y" && true}
                      onClick={() => this.Residency("HOUSE2030")}
                    />
                    <div>{t("search.youth")}</div>
                  </label>
                </div>
              </div>
            </div>
            <div className={cx("filter-list-right")}>
              <div className={cx("deposit")}>
                <h3>
                  {t("search.deposit")}
                  <span>
                    {this.numberWithCommas(this.state.deposit["min"])}~
                    {this.state.deposit["max"] === 100000000
                      ? "1억+"
                      : this.numberWithCommas(this.state.deposit["max"])}
                  </span>
                </h3>
                <div>
                  <InputRange
                    maxValue={100000000}
                    minValue={0}
                    step={1000000}
                    value={this.state.deposit}
                    onChange={deposit => this.setState({ deposit })}
                    onChangeComplete={this.SearchKeyword}
                  />
                </div>
                <div className={cx("range-helper")}>
                  <span>0</span>
                  <span>5천만</span>
                  <span>최대</span>
                </div>
                <h3>
                  {t("search.monthly")}
                  <span>
                    {this.numberWithCommas(this.state.monthly["min"])}~
                    {this.state.monthly["max"] === 2000000
                      ? "2백만+"
                      : this.numberWithCommas(this.state.monthly["max"])}
                  </span>
                </h3>
                <div>
                  <InputRange
                    maxValue={2000000}
                    minValue={0}
                    step={50000}
                    value={this.state.monthly}
                    onChange={monthly => this.setState({ monthly })}
                    onChangeComplete={this.SearchKeyword}
                  />
                </div>
                <div className={cx("range-helper")}>
                  <span>0</span>
                  <span>50만원</span>
                  <span>최대</span>
                </div>
              </div>
              <div className={cx("residency")}>
                <h3>{t("search.condition")}</h3>
                <div>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.GENDER_WOMAN === "Y" && true}
                      onClick={() => this.Residency("GENDER_WOMAN")}
                    />
                    <div>{t("search.female")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.GENDER_MAN === "Y" && true}
                      onClick={() => this.Residency("GENDER_MAN")}
                    />
                    <div>{t("search.male")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.GENDER_ALL === "Y" && true}
                      onClick={() => this.Residency("GENDER_ALL")}
                    />
                    <div>{t("search.adult")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.NEW_OPEN === "Y" && true}
                      onClick={() => this.Residency("NEW_OPEN")}
                    />
                    <div>{t("search.new")}</div>
                  </label>
                </div>
                <h3>{t("search.add")}</h3>
                <div>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.FAT === "Y" && true}
                      onClick={() => this.Residency("FAT")}
                    />
                    <div>{t("search.pet")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.DEPOSIT_ADJUST === "Y" && true}
                      onClick={() => this.Residency("DEPOSIT_ADJUST")}
                    />
                    <div>{t("search.deposit.control")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.FOREIGNER === "Y" && true}
                      onClick={() => this.Residency("FOREIGNER")}
                    />
                    <div>{t("search.foreigner")}</div>
                  </label>
                  <label>
                    <input
                      readOnly
                      type="checkbox"
                      checked={this.state.NOW_MOVIN === "Y" && true}
                      onClick={() => this.Residency("NOW_MOVIN")}
                    />
                    <div>{t("search.instant")}</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx("search-help")}
          data-help-toggle={this.state.Isfocus || this.state.mobileHelp}
        >
          {window.screen.width < 768 && (
            <div
              className={cx("helpClose")}
              onClick={() => {
                this.setState({
                  Isfocus: false,
                  mobileHelp: false
                });
              }}
            >
              X
            </div>
          )}
          {(!NowSearchKeyHelper || NowSearchKeyHelper.length > 0) &&
            NowSearchKeyHelper.map((item, index) => {
              if (NowSearchKeyHelper === "") {
                return null;
              } else {
                if (item["CATEGORY"] === "1") {
                  return (
                    <div
                      className={cx("help-content")}
                      key={index}
                      onClick={() => this.SearchKeyword(index)}
                    >
                      <img src={category1} alt="대학교" />
                      <p>
                        {ReactHtmlParser(
                          this.highlightkeyword(item["KEYWORD"])
                        )}
                      </p>
                    </div>
                  );
                } else if (item["CATEGORY"] === "2") {
                  return (
                    <div
                      className={cx("help-content")}
                      key={index}
                      onClick={() => this.SearchKeyword(index)}
                    >
                      <img src={category2} alt="지하철" />
                      <p>
                        {ReactHtmlParser(
                          this.highlightkeyword(item["KEYWORD"])
                        )}
                      </p>
                    </div>
                  );
                } else if (item["CATEGORY"] === "3") {
                  return (
                    <div
                      className={cx("help-content")}
                      key={index}
                      onClick={() => this.SearchKeyword(index)}
                    >
                      <img src={category3} alt="법정동 주소" />
                      <p>
                        {ReactHtmlParser(
                          this.highlightkeyword(item["KEYWORD"])
                        )}
                      </p>
                    </div>
                  );
                } else if (item["CATEGORY"] === "4") {
                  return (
                    <div
                      className={cx("help-content")}
                      key={index}
                      onClick={() => this.SearchKeyword(index)}
                    >
                      <img src={category4} alt="하우스명" />
                      <p>
                        {ReactHtmlParser(
                          this.highlightkeyword(item["KEYWORD"])
                        )}
                      </p>
                    </div>
                  );
                } else {
                  return null;
                }
              }
            })}
        </div>
        <div className={cx("search-result")}>
          <div
            className={cx("search-result-left")}
            ref={ref => (this.mapRef = ref)}
          ></div>
          <div
            className={cx(
              "search-result-right",
              {
                filterOn: this.state.ShowFlilter
              },
              { searchON: this.state.Isfocus || this.state.mobileHelp }
            )}
            ref={ref => (this.searchUp = ref)}
          >
            <div
              className={cx("arrow-btn")}
              onClick={e => this.searchScroll(e)}
              ref={ref => (this.searchUpBtn = ref)}
            >
              <img src={rectangle255} alt="" />
              <div className={cx("arrow")}>
                {this.state.searchUp ? (
                  <img src={arrow_down} alt="arrow_down" />
                ) : (
                  <img src={arrow_up} alt="arrow_up" />
                )}
              </div>
            </div>
            <div
              className={cx("result-head")}
              onClick={e => this.searchScroll(e)}
            >
              <p>
                {t("search.results")}&nbsp;&nbsp;
                <span>&nbsp;{this.props.totalCount}</span>
                {t("search.count")}
              </p>
              <button
                onClick={e => this.openModal(e)}
                ref={ref => {
                  this.compareBtn = ref;
                }}
              >
                <img src={folder_add} alt="folder_add" />
                {t("search.comparison")} ({this.state.compareList.length})
              </button>
            </div>
            <div
              className={cx("result-body")}
              ref={this.scrollRef}
              onScroll={this.handleScroll}
            >
              {!this.props.houseLoad &&
                this.props.houseList.map((item, index) => {
                  if (index > this.state.ROWS) return null;
                  return (
                    <Link
                      to={"/app" + item["HOUSE_URL"]}
                      key={index}
                      // target="_blank"
                    >
                      <div className={cx("result-item")}>
                        <div className={cx("item-img")}>
                          <Image
                            src={item["INFO_THUMB_URL"]}
                            alt={item["HOUSE_NAME"]}
                          />
                          <img
                            src={item["IS_FAVORITED_YN"] === "Y" ? star : star2}
                            alt="star"
                            className={cx("star")}
                            onClick={e => {
                              this.favorite(
                                e,
                                item.IS_FAVORITED_YN,
                                item.HOUSE_ID
                              );
                            }}
                          />
                          {item["VR_HOUSE_YN"] === "Y" && (
                            <img src={vr} alt="vr" className={cx("vr")} />
                          )}
                          <div
                            className={cx("add-compare")}
                            onClick={e =>
                              this.AddCompareList(e, item["HOUSE_ID"])
                            }
                          >
                            <img src={folder_add_w} alt="folder_add_w" />
                            {t("search.comparisons")}
                          </div>
                        </div>
                        <div className={cx("item-content")}>
                          <div className={cx("types")}>
                            {item["AD_HOUSE_YN"] === "Y" && (
                              <button className={cx("nomination-btn")}>
                                {t("search.recommendation")}
                              </button>
                            )}
                            <button className={cx("primary-blue-btn")}>
                              {item["RENT_TYPE_NAME"]}
                            </button>
                            <span>{item["HOUSE_TYPE_NAME"]}</span>
                          </div>
                          <span className={cx("item-title")}>
                            {item["BRAND_NAME"]} {item["HOUSE_NAME"]}
                          </span>
                          <span className={cx("item-location")}>
                            {item["ADDRESS"]}
                          </span>
                          <div className={cx("item-price")}>
                            <p>
                              <span>{t("search.monthly")}</span>
                              <span>{item["RENTFEE"]}</span>
                            </p>
                            <p>
                              <span>{t("search.deposit")}</span>
                              <span>{item["DEPOSIT"]}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          <div className={cx("clear'")}></div>
        </div>
        <div className={cx("clear")}></div>
        <>
          <Popup
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          >
            <Compare
              closeModal={this.closeModal}
              isModalOpen={this.state.isModalOpen}
              className={cx("compareWrap")}
            />
          </Popup>
          <Popup
            closeModal={this.loginCloseModal}
            isModalOpen={this.state.isLoginModalOpen}
          >
            <Login
              closeModal={this.loginCloseModal}
              isModalOpen={this.state.isLoginModalOpen}
            />
          </Popup>
        </>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Search));
