import React from "react";
import classNames from "classnames/bind";

import Loading from "assets/images/loading.png";
import star_gray from "assets/images/Star_gray.png";
import folder_add from "assets/images/folder_add.png";
import share from "assets/images/share.png";
import kakao_share from "assets/images/kakao_share.png";
import facebook_share from "assets/images/facebook_share.png";
import twitter_share from "assets/images/twitter_share.png";
import link_share from "assets/images/link_share.png";

import styles from "./_DetailSkeleton.module.scss";

const cx = classNames.bind(styles);

const DetailSkeleton = () => {
  window.scrollTo(0, 0);
  return (
    <div className={cx("wrap")}>
      <section className={cx("left")}>
        <div className={cx("slide")}>
          <img src={Loading} alt="로딩중..." />
        </div>
      </section>
      <section className={cx("right")}>
        <div className={cx("fixed-info")}>
          <div className={cx("tag-box")}>데이터 조회중입니다.</div>
          <h1>데이터 조회중입니다.</h1>
          <p className={"info-notice"}>데이터 조회중입니다.</p>
          <form>
            <select defaultValue="">
              <option value="">방을 선택하여 주세요</option>
            </select>
            <input type="button" value="집주인과 상의하기" />
          </form>
          <div className={cx("util-buttons")}>
            <div>
              <img src={star_gray} alt="fav" />
            </div>
            <div>
              <img src={folder_add} alt="folder" className={cx("share")} />
            </div>
            <div>
              <img src={share} alt="share" />
            </div>
            <div className={cx("hover-util")}>
              <button id="kakao">
                <img src={kakao_share} alt="kakao_share" />
              </button>
              <button>
                <img src={facebook_share} alt="facebook_share" />
              </button>

              <button>
                <img src={twitter_share} alt="twitter_share" />
              </button>

              <button>
                <img src={link_share} alt="link_share" />
              </button>
            </div>
            <button>
              <img src={folder_add} alt="folder_add" />
              비교함 0
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailSkeleton;
