import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Know from "components/dictionary/detail/young/know";
import * as UserAction from "stores/modules/mainall";
import { resCode } from "lib/resCode";

class YoungContainer extends React.Component {
  // 쉐어하우스 스토리 페이지 뿌려주는 함수

  state={
    articleList: []
  }

  componentDidMount() {
    this.ArticleContent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ARTICLE_LIST !== this.props.ARTICLE_LIST) {
      let State = this.state;
      State['articleList'] = [];
      State["articleList"] = nextProps["ARTICLE_LIST"];
      this.setState(State);
      return true;
    }
    return false;
  }

  ArticleContent = async () => {
    const { UserAction, SEARCH_TEXT } = this.props;
    try {
      const res = await UserAction.ArticleContent({SEARCH_TEXT, CATEGORY: '0006'});
      const data = resCode(res);

      let nextState = this.state;
      nextState['articleList'] = data.articleList;
      this.setState(nextState);
      this.forceUpdate();
      return;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return <Know 
              nowCategory={this.props.nowCategory}
              youngCategory={this.props.youngCategory}
              articleList={this.state.articleList}
              ArticleContent2={this.ArticleContent2} 
            />;
  }
}

export default connect(state => ({
  SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT,
  ARTICLE_LIST: state.mainall.article.content.ARTICLE_LIST
}), dispatch => ({
  UserAction: bindActionCreators(UserAction, dispatch)
}))(YoungContainer);
