import React from "react";
import styles from "./_ExpiredEmail.module.scss";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class _ExpiredEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  goHome = () => {
    window.location.replace("/app");
  };
  render() {
    const { t } = this.props;
    return (
      <div className={cx("chpw-wrap")}>
        <div>
          <h1>{t("email.expire")}</h1>
          <p>{t("email.expireText")}</p>
          <button onClick={this.goHome}>{t("email.goHome")}</button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(_ExpiredEmail);
