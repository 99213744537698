import React from "react";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/user";
import { withTranslation } from "react-i18next";

import qs from "query-string";
import client from "lib/client";
import { encode } from "lib/encrypt";

class SNSLoginContainer extends React.Component {
  componentDidMount = async () => {
    const { t } = this.props;
    try {
      const { UserActions } = this.props;
      const { type } = this.props.match.params;
      const { code } = qs.parse(this.props.location.search);
      const MyStorage = localStorage;

      const deviceToken = MyStorage.getItem("deviceToken");

      if (type === "kakaotalk") {

        const res = await UserActions.LoginKaKao({ code, deviceToken });

        let LoginData = {
          member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
          access_token: res["headers"]["x-access-token"],
          refresh_token: res["headers"]["x-refresh-token"]
        };

        LoginData = encode(JSON.stringify(LoginData));

        // this.props.cookies.set("auth", LoginData, {
        //   path: "/app"
        // });

        MyStorage.setItem("auth", LoginData);

        // window.location.reload();
        
        let token = LoginData;
        token = !token.startsWith('auth=') ? 'auth=' + token: token;
        window.ReactNativeWebView.postMessage("cookie|" + token);
        
        window.ReactNativeWebView.postMessage("cookie|" + token);
        window.ReactNativeWebView.postMessage("login|" + LoginData);
        
        // let token = this.props.cookies.get("auth");
        // window.ReactNativeWebView.postMessage("cookie|" + token);
        
        // this.props.history.replace("/app");
        this.props.history.push('/app');

      } else if (type === "naver") {
        const res = await UserActions.LoginNaver({ code, deviceToken });
        let LoginData = {
          member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
          access_token: res["headers"]["x-access-token"],
          refresh_token: res["headers"]["x-refresh-token"]
        };

        LoginData = encode(JSON.stringify(LoginData));

        MyStorage.setItem("auth", LoginData);
        // this.props.cookies.set("auth", LoginData, {
        //   path: "/app"
        // });

        // window.location.reload();
        
        let token = LoginData;
        token = !token.startsWith('auth=') ? 'auth=' + token: token;
        window.ReactNativeWebView.postMessage("cookie|" + token);
        
        window.ReactNativeWebView.postMessage("cookie|" + token);
        window.ReactNativeWebView.postMessage("login|" + LoginData);
        
        this.props.history.push('/app');
        // let token = this.props.cookies.get("auth");
        // window.ReactNativeWebView.postMessage("cookie|" + token);

        // this.props.history.replace("/app");
        

      } else {
        // alert(t("login.notNormal"));
        window.ReactNativeWebView.postMessage("sendAlert|" + t("login.notNormal"));
        // window.location.replace("/app");
        this.props.history.push('/app');
      }
    } catch (e) {
      console.log(e);
      // alert(t("login.notNormal"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("login.notNormal"));
      this.props.history.push('/app');
      // window.location.replace("/app");
    }
  };

  render() {
    return null;
  }
}
export default withTranslation()(
  withCookies(
    connect(null, dispatch => ({
      UserActions: bindActionCreators(UserActions, dispatch)
    }))(withRouter(SNSLoginContainer))
  )
);
