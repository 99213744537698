import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames/bind";

import heart from "assets/images/heart.png";
import Play from "assets/images/play.png";
import comment from "assets/images/comment.png";

import styles from "./_Dictionary.module.scss";
const cx = classnames.bind(styles);

class Story extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ArticleArr: []
    };
  }

  componentDidMount() {
    this.props.ArticleContent().then(array => {
      this.setState({
        ArticleArr: array
      });
      this.forceUpdate();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ArticleArr !== this.props.ArticleArr) {
      let State = this.state;
      State["ArticleArr"] = nextProps["ArticleArr"];
      this.setState(State);
      return true;
    }
    return false;
  }

  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push("/app" + item["ARTICLE_URL"]);
    } else {
      // window.open(item["ARTICLE_URL"], "_blank");
      window.ReactNativeWebView.postMessage("open|" + item["ARTICLE_URL"]);
    }
  };

  render() {
    return (
      <div className={cx("dictionary-wrap")}>
        <div className={cx("dictionary-contents")}>
          <div className={cx("contents-list")}>
            <div className={cx("story")}>
              <div>
                {this.state.ArticleArr.filter(
                  items => items["CATEGORY"] === "0003"
                ).map((items, index) => {
                  return (
                    <div
                      onClick={e => this.goto(items)}
                      className={cx("story-card")}
                      key={items["ARTICLE_ID"]}
                    >
                      <div className={cx("card-image")}>
                        <img src={items["INFO_THUMB_URL"]} alt="card" />
                        {items["ARTICLE_TYPE"] === "2" && (
                          <span>
                            <img src={Play} alt="" />
                          </span>
                        )}
                      </div>
                      <div className={cx("card-title")}>
                        <a href={items["ARTICLE_URL"]} target="BLANK">
                          {items["BRAND_NAME"] + " " + items["TITLE"]}
                        </a>
                      </div>
                      <div className={cx("card-footer")}>
                        <span>{items["CREATE_DT"]}</span>
                        {items["ARTICLE_TYPE"] === "1" && (
                          <>
                            <span>
                              <img src={heart} alt="heart" />
                              {items["LIKE_CNT"]}
                            </span>
                            <span>
                              <img src={comment} alt="comment" />
                              {items["COMMENT_CNT"]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Story);
