import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import client from "lib/client";

const INQUIRY = "INQUIRY";
const HOUSE_INQUERY = "HOUSEINQUERY";
const INQUIRY_DETAIL = "INQUIRY_DETAIL";
const HOUSE_MESSAGE_QUERY = "HOUSE_MESSAGE_QUERY";
const INQUERY_DELETE = "INQUERY_DELETE";

const Inquiry_API = () => {
  //입주문의 내역 api
  return client.get("/rest/my/inquiry/message");
};
const Houseinquery_API = (houseid, message, inquiry_id) => {
  return client.post(
    `/rest/my/inquiry/message?HOUSE_ID=${houseid}&MESSAGE=${message}&INQUIRY_ID=${inquiry_id}`
  );
};
const inquiry_detail_API = ({ inquiry_id }) => {
  return client.get("/rest/my/inquiry/message/" + inquiry_id);
};
const houseMessageListAPI = () => {
  return client.get("/rest/my/inquiry/message");
};
const inquery_delete_API = inquiry_id => {
  return client.delete("/rest/my/inquiry/message/" + inquiry_id);
};

export const ArticleContent = createAction(INQUIRY, Inquiry_API); // 입주문의 내역 api
export const inquiry_detail = createAction(INQUIRY_DETAIL, inquiry_detail_API);
export const HouseInquery = createAction(HOUSE_INQUERY, Houseinquery_API);
export const houseMessage = createAction(
  HOUSE_MESSAGE_QUERY,
  houseMessageListAPI
);
export const inquery_delete = createAction(INQUERY_DELETE, inquery_delete_API);

const initialState = {};

export default handleActions(
  {
    ...pender({
      type: INQUIRY,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: HOUSE_INQUERY,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: INQUIRY_DETAIL,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: HOUSE_MESSAGE_QUERY,
      // onPending : () => {

      // },
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: INQUERY_DELETE,
      onSuccess: () => {
        return;
      }
    })
  },
  initialState
);
