import React from "react";
import classnames from "classnames/bind";
import styles from "./_Login.module.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import sns_naver from "assets/images/Naver.png";
import sns_kakao from "assets/images/kakao.png";
import { compose } from "redux";
// import sns_facebook from "assets/images/facebook_login.png";

const cx = classnames.bind(styles);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      userPw: ""
    };
  }

  // 비밀번호 나 아이디 입력되면 state 에 저장 ..
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // 로그인 api 에 state 값 던져줌 아이디 나 비밀번호 빠지면 alert 창 띄움
  Login = e => {
    e.preventDefault();
    const { t } = this.props;
    this.props.button();

    if (this.props.loginButton === true) return null;

    if (this.state.userId === "") {
      // alert(t("login.idAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("login.idAlert"));
      return false;
    }
    if (this.state.userPw === "") {
      // alert(t("login.pwAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("login.pwAlert"));
      return false;
    }

    this.props.LoginNormal({
      userId: this.state.userId,
      userPw: this.state.userPw
    });
  };

  loginWithKakao = e => {
    e.preventDefault();

    window.ReactNativeWebView.postMessage("onChangeTitle|" + "SNS");

    const app_key = "44986ce2faa9dc754f4c9306c75371cb";
    const state = encodeURI(window.location.origin);
    const redirect_uri = encodeURI(
      window.location.origin + "/app/sns/kakaotalk/oauth"
    );

    // window.location.href(
    //   `https://kauth.kakao.com/oauth/authorize?client_id=${app_key}&redirect_uri=${redirect_uri}&state=${state}&response_type=code&encode_state=y`
    // );
    window.location.assign(
      `https://kauth.kakao.com/oauth/authorize?client_id=${app_key}&redirect_uri=${redirect_uri}&state=${state}&response_type=code&encode_state=y`
    );
  };

  loginNaver = e => {
    e.preventDefault();

    window.ReactNativeWebView.postMessage("onChangeTitle|" + "SNS");

    const app_key = "CZNs3mukoFjI80HT89oC";
    const state = encodeURI(window.location.origin);
    const redirect_uri = window.location.origin + "/app/sns/naver/oauth";

    // window.open(
    //   `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${app_key}&redirect_uri=${redirect_uri}&state=${state}`,
    //   "_blank"
    // );
    // window.ReactNativeWebView.postMessage(
    //   "open|" +
    //     `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${app_key}&redirect_uri=${redirect_uri}&state=${state}`
    // );
    window.location.assign(
      `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${app_key}&redirect_uri=${redirect_uri}&state=${state}`
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={cx("Login")}>
        <div className={cx("login-wrap")}>
          <button
            className={cx("close")}
            onClick={() => {
              this.props.closeModal();
              const page = window.location.pathname;
              window.ReactNativeWebView.postMessage(`isMain|${page}|true`);
            }}
          ></button>
          <h1>{t("login.login")}</h1>
          <span>{t("login.title")}</span>
          <form>
            <label>
              <input
                type="button"
                value={t("login.naverLogin")}
                onClick={this.loginNaver}
              />
              <img src={sns_naver} alt="sns-icon" />
            </label>
            <label>
              <input
                type="button"
                value={t("login.kakaoLogin")}
                onClick={this.loginWithKakao}
              />
              <img src={sns_kakao} alt="sns-icon" />
            </label>
            {/* <label>
              <input type="button" value="페이스북 계정으로 로그인" />
              <img src={sns_facebook} alt="sns-icon" />
            </label> */}
          </form>
          <form>
            <label>
              <input
                type="email"
                placeholder={t("login.email")}
                name="userId"
                onChange={e => this.onChange(e)}
              />
            </label>
            <label>
              <input
                type="password"
                placeholder={t("login.password")}
                name="userPw"
                onChange={e => this.onChange(e)}
              />
            </label>
            <input
              type="submit"
              value={t("login.loginBtn")}
              onClick={e => this.Login(e)}
            />
            <div>
              <label>
                <input type="checkbox" />
                <span>{t("login.autoLogin")}</span>
              </label>
              <div>
                <Link
                  to="/app/user/passwordfind"
                  onClick={this.props.closeModal}
                >
                  {t("login.findPwd")}
                </Link>
                <Link
                  to="/app/user/Register"
                  state={123456}
                  onClick={this.props.closeModal}
                >
                  {t("login.register")}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
