import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import client from "lib/client";

const CONTENTDETAIL = "CONTENTDETAIL";
const ADDCOMMENT = "ADDCOMMENT";
const DELCOMMENT = "DELCOMMENT";
const MODICOMMENT = "MODICOMMENT";
const SEEMYSCRAPS = "SEEMYSCRAPS";
const ADDSCRAPS = "ADDSCRAPS";
const DELETESCRAPS = "DELETESCRAPS";
const ADDLIKE = "ADDLIKE";
const DELETELIKE = "DELETELIKE";

const PageDetail_API = pageid => {
  return client.get(`/rest/${pageid}`); // 콘텐츠 상세정보 조회
};

const AddComment_API = (articlepath, message, seq) => {
  if (seq === null || seq === undefined || seq === "") {
    return client.post(
      `/rest/article/${articlepath}/comment?MESSAGE=${message}`
    ); // 일반 댓글 작성
  } else {
    return client.post(
      `/rest/article/${articlepath}/comment?MESSAGE=${message}&ORG_SEQ=${seq}`
    ); // 대댓글 작성시 사용...
  }
};
// 댓글 수정
const ModiComment_API = (articleId, message, seq) => {
  return client.post(
    `/rest/article/${articleId}/comment?MESSAGE=${message}&SEQ=${seq}`
  );
};

// 콘텐츠 댓글 삭제
const DelComment_API = (articleId, seq) => {
  return client.delete(`/rest/article/${articleId}/comment/${seq}`);
};

// 스크랩
const Seemyscrap_API = () => {
  return client.get("/rest/my/favorite/article");
};
const AddScraps_API = houseId => {
  return client.post(`/rest/my/favorite/article/${houseId}`);
};
const DeleteScraps_API = houseId => {
  return client.delete(`/rest/my/favorite/article/${houseId}`);
};

// 좋아요 추가
const ADDLike_API = houseId => {
  return client.post(`/rest/my/like/article/${houseId}`);
};

// 좋아요 삭제
const DELETELike_API = houseId => {
  return client.delete(`/rest/my/like/article/${houseId}`);
};

export const AddComment = createAction(ADDCOMMENT, AddComment_API);
export const delComment = createAction(DELCOMMENT, DelComment_API);
export const ModiComment = createAction(MODICOMMENT, ModiComment_API);
export const ContentDetail = createAction(CONTENTDETAIL, PageDetail_API); // 콘텐츠 상세 보기
export const Seemyscrap = createAction(SEEMYSCRAPS, Seemyscrap_API); // 나의 관심목록 콘텐츠 목록-
export const AddScraps = createAction(ADDSCRAPS, AddScraps_API); // 나의 관심목록 콘텐츠 추가
export const DeleteScraps = createAction(DELETESCRAPS, DeleteScraps_API);
export const AddLike = createAction(ADDLIKE, ADDLike_API);
export const DeleteLike = createAction(DELETELIKE, DELETELike_API);

const initialState = {};

export default handleActions(
  {
    ...pender({
      type: CONTENTDETAIL,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: ADDCOMMENT,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: ADDSCRAPS,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: DELETESCRAPS,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: SEEMYSCRAPS,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: DELCOMMENT,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: MODICOMMENT,
      onSuccess: () => {
        return;
      }
    })
  },
  initialState
);
