import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/mainall";

import DeclinedEmail from "components/user/DeclinedEmail/DeclinedEmail";

class DeclinedEmailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmId: "",
      isLoading: true
    };
  }

  componentDidMount() {
    this.pwdModiEmailCheck();
  }

  pwdModiEmailCheck = async () => {
    const { UserAction } = this.props;
    let confirmId = this.props.match.params.confirmId;
    let nextState = this.state;
    try {
      const res = await UserAction.pwdModiEmailCheck(confirmId);
      if (res.data.result.resCode === "0000") {
        nextState["confirmId"] = confirmId;
        nextState["isLoading"] = false;
        this.setState(nextState);
        this.emailRefuse();
        return res;
      } else if (res.data.result.resCode === "0002") {
        window.location.replace("/app/user/expiredemail");
      } else {
        window.location.replace("/app/error/" + res.data.result.resCode);
      }
    } catch (e) {
      console.log(e);
    }
  };

  emailRefuse = async () => {
    const { UserAction } = this.props;
    try {
      const res = await UserAction.emailRefuse(this.state.confirmId);
      return res.data.result.data;
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    if (this.state.isLoading) return null;
    return <DeclinedEmail />;
  }
}

export default connect(null, dispatch => ({
  UserAction: bindActionCreators(UserAction, dispatch)
}))(DeclinedEmailContainer);
