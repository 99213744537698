import React from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import styles from "./_Header.module.scss";

import search from "assets/images/search-icon.png";
import logo from "assets/images/logo.png";

import Popup from "components/common/Popup";
import Login from "containers/user/login/UserLoginContainer";
import AsideHam from "components/common/AsideHam";

const cx = classNames.bind(styles);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowHam: false
    };
  }

  ToggleHam = e => {
    e.preventDefault();

    this.setState({
      isShowHam: !this.state.isShowHam
    });
    if (this.state.isShowHam) {
      document.getElementById("root").style.position = "static";
      document.getElementById("root").style.width = "auto";
      document.getElementById("root").style.height = "auto";
    } else {
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.width = "100%";
      document.getElementById("root").style.height = "100%";
    }
  };

  closeMenu = () => {
    this.setState({ isShowHam: false });
    document.getElementById("root").style.position = "static";
    document.getElementById("root").style.width = "auto";
    document.getElementById("root").style.height = "auto";
  };
  openModal = e => {
    this.props.openModal(e);
    this.closeMenu();
  };

  linkMove = () => {
    this.setState({ ...this.state, isShowHam: false });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.member_name ? (
          <header
            className={cx("invert", { positionFix: this.props.HeaderFixed })}
          >
            <div className={cx("content")}>
              <button className={cx("ham-menu")} onClick={this.ToggleHam}>
                <div className={cx("line")}></div>
                <div className={cx("line")}></div>
                <div className={cx("line")}></div>
              </button>
              <nav className={cx("navigation")}>
                <Link to="/article">{t("header.encyclopedia")}</Link>
                <a href="https://biz.thecomenstay.com">{t("header.buspage")}</a>
              </nav>
              <h2>
                <Link to="/">
                  <img src={logo} alt="logo" title="logo" />
                </Link>
              </h2>
              <h2 className={cx("search-icon")}>
                <Link to="/search">
                  <img src={search} alt="search" />
                </Link>
              </h2>
              <div className={cx("member-wrap")}>
                <Link to="/search">{t("header.searchhouse")}</Link>
                <Link to="/user/mypage">{t("header.nickname")}</Link>
                <a
                  href="/"
                  className={cx("logOut")}
                  onClick={this.props.Logout}
                >
                  {t("header.logout")}
                </a>
              </div>
            </div>
          </header>
        ) : (
          <header className={cx("invert")}>
            <div className={cx("content")}>
              <button className={cx("ham-menu")} onClick={this.ToggleHam}>
                <div className={cx("line")}></div>
                <div className={cx("line")}></div>
                <div className={cx("line")}></div>
              </button>
              <nav className={cx("navigation")}>
                <Link to="/article">{t("header.encyclopedia")}</Link>
                <a href="https://biz.thecomenstay.com">{t("header.buspage")}</a>
              </nav>
              <h2>
                <Link to="/">
                  <img src={logo} alt="logo" title="logo" />
                </Link>
              </h2>
              <h2 className={cx("search-icon")}>
                <Link to="/search">
                  <img src={search} alt="search" />
                </Link>
              </h2>
              <div className={cx("member-wrap")}>
                <Link to="/search">{t("header.searchhouse")}</Link>
                <Link to="/user/register">{t("header.join")}</Link>
                <a href="/" onClick={this.props.openModal}>
                  {t("header.login")}
                </a>
              </div>
            </div>
          </header>
        )}
        <AsideHam
          ToggleHam={this.ToggleHam}
          isShowHam={this.state.isShowHam}
          openModal={this.openModal}
          memberName={this.props.member_name}
          Logout={this.props.Logout}
          closeMenu={this.closeMenu}
        />
        <Popup
          closeModal={this.props.closeModal}
          isModalOpen={this.props.isModalOpen}
        >
          <Login
            closeModal={this.props.closeModal}
            isModalOpen={this.props.isModalOpen}
          />
        </Popup>
      </>
    );
  }
}

export default withTranslation()(Header);
