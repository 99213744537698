import React from "react";
import Comic from "components/dictionary/detail/Comic";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MainallAction from "stores/modules/mainall";

class ComicContainer extends React.Component {
  ArticleContent = async () => {
    const { MainallAction, SEARCH_TEXT } = this.props;
    try {
      const res = await MainallAction.ArticleContent({SEARCH_TEXT, CATEGORY: '0002'});
      return res.data.result.data.articleList;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return <Comic ArticleContent={this.ArticleContent} ArticleArr={this.props.ARTICLE_LIST}/>;
  }
}

export default connect(state => ({
  SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT,
  ARTICLE_LIST: state.mainall.article.content.ARTICLE_LIST
}), dispatch => ({
  MainallAction: bindActionCreators(MainallAction, dispatch)
}))(ComicContainer);
