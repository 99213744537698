import React from "react";
import { withTranslation } from "react-i18next";
import classnames from "classnames/bind";
import styles from "./_PasswordFind.module.scss";
import ReactHtmlParser from "react-html-parser";
// import sns_naver from "assets/images/Naver.png";
// import sns_kakao from "assets/images/kakao.png";
// import sns_facebook from "assets/images/facebook_login.png";

const cx = classnames.bind(styles);

class PasswordFind extends React.Component {
  state = {
    EMAIL: ""
  };

  onChange = e => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    let nextState = this.state;
    nextState[name] = value;
    this.setState(nextState);
  };

  passwordFind = e => {
    e.preventDefault();
    const {t} = this.props;
    const EMAIL = this.state.EMAIL;
    const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식

    if (!emailRule.test(EMAIL)) {
      // alert(t("myPage.emailAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.emailAlert"));
      return false;
    }

    this.props.passwordFind({ EMAIL });
  };

  render() {
    const {t} = this.props;
    return (
      <div className={cx("modp")}>
        <div className={cx("modp-wrap")}>
          <h1>{t("myPage.findPwd")}</h1>
          <span>
            {ReactHtmlParser(t("myPage.findPwdText"))}
          </span>

          <form onSubmit={this.passwordFind}>
            <label>
              <input
                name="EMAIL"
                type="email"
                placeholder={t("myPage.emailInput")}
                onChange={this.onChange}
              />
            </label>
            <input type="submit" value={t("myPage.send")} />
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PasswordFind);
