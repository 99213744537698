import React from "react";
import classNames from "classnames/bind";
import Header from "containers/common/header/HedaerContainer";
import Footer from "components/common/Footer/Footer";
import MobileFooter from "components/common/MobileFooter/MobileFooter";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MainAction from "stores/modules/main";

import topBtn from "assets/images/topBtn.png";

import styles from "./_MainTemplate.module.scss";

import Popup from "components/common/Popup";
import Login from "containers/user/login/UserLoginContainer";

const cx = classNames.bind(styles);

class MainTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrev: true,
      isModalOpen: false,
      HeaderFixed: false,
    };
  }

  componentDidMount() {
    //  스크롤감지
    window.addEventListener("scroll", this.onScroll);

    // 로그인 모달 열기
    window.OpenModal = () => {
      this.setState({ isModalOpen: true });
    };

    // 회원가입이동
    window.GoSignUp = () => {
      this.props.history.push("/app/user/register");
    };

    // 링크 이동
    window.GoPush = (urls) => {
      this.props.history.push(urls);
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  // 모달 열기
  openModal = (e) => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  // 모달 닫기
  closeModal = (e) => {
    this.setState({ isModalOpen: false });
  };

  // 헤더 위치 변경
  headerPosition = () => {
    let nextState = this.state;
    nextState["HeaderFixed"] = !this.state.HeaderFixed;
    this.setState(nextState);
  };

  // 뒤로가기
  goPrev = () => {
    this.props.history.goBack();
  };

  // 상단으로 이동
  toTop = () => {
    window.scrollTo(0, 0);
  };

  // 스크롤 감지해서 버튼 보이기/감추기
  onScroll = (e) => {
    const scrollTop = ("scroll", e.srcElement.scrollingElement.scrollTop);
    if (window.screen.width < 768) {
      // 최상단 이동 버튼 작동
      if (scrollTop > 480) {
        this.topBtn.style.display = "block";
      } else if (scrollTop < 480) {
        this.topBtn.style.display = "none";
      }
    }
  };

  render() {
    return (
      <div className={cx("base")}>
        {/* <Header
          openModal={this.openModal}
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
          HeaderFixed={this.state.HeaderFixed}
        /> */}
        {this.props.children}
        {/* <div
          className={cx("goPrev")}
          onClick={this.goPrev}
          id="mainTemplate_goPrev_btn"
        >
          <p>
            <span></span>
            <span></span>
          </p>
        </div> */}
        <div
          className={cx("toTop")}
          onClick={this.toTop}
          ref={(ref) => {
            this.topBtn = ref;
          }}
          id="mainTemplate_toTop_btn"
        >
          <img src={topBtn} alt="topBtn" />
        </div>
        {/* <Footer /> */}
        {/* <MobileFooter /> */}
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
        >
          <Login
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          />
        </Popup>
      </div>
    );
  }
}

export default withRouter(MainTemplate);
