import React from "react";
import classnames from "classnames/bind";
import styles from "./_Dictionary.module.scss";
import { withRouter, Link } from "react-router-dom";
import heart from "assets/images/heart.png";
import comment from "assets/images/comment.png";
import user_avata from "assets/images/user-avata.png";
import Image from "assets/images/default-image.png";

const cx = classnames.bind(styles);

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ArticleArr: [],
      cate: this.props.category
    };
  }

  onImageError = e => {
    e.currentTarget.src = Image;
  };

  componentDidMount() {
    this.props.ArticleContent().then(array => {
      this.setState({
        ArticleArr: array
      });
      this.forceUpdate();
    });
    if (
      this.props.category === "" ||
      this.props.category === undefined ||
      this.props.category === null
    ) {
      this.setState({
        cate: "쉐어하우스 리포트"
      });
      this.forceUpdate();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ArticleArr !== this.props.ArticleArr) {
      let State = this.state;
      State["ArticleArr"] = nextProps["ArticleArr"];
      this.setState(State);
      return true;
    }
    return false;
  }

  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push("/app" + item["ARTICLE_URL"]);
    } else {
      // window.open(item["ARTICLE_URL"], "_blank");
      window.ReactNativeWebView.postMessage("open|" + item["ARTICLE_URL"]);
    }
  };

  render() {
    return (
      <div className={cx("dictionary-wrap")}>
        <div className={cx("dictionary-contents")}>
          <div className={cx("contents-list")}>
            <div className={cx("houselife-report")}>
              <div>
                {this.state.ArticleArr.filter(
                  items => items["CATEGORY_NAME"] === this.state.cate
                ).map(items => {
                  return (
                    <div
                      onClick={e => this.goto(items)}
                      className={cx("third-cols")}
                      key={items["ARTICLE_ID"]}
                    >
                      <div className={cx("image-cover")}>
                        <img
                          src={items["INFO_THUMB_URL"]}
                          alt="게시물 이미지"
                          onError={this.onImageError}
                        />
                      </div>
                      <h3>
                        {items["ARTICLE_TYPE"] !== "1" ? (
                          <a href={items["ARTICLE_URL"]} target="blank">
                            {items["TITLE"]}
                          </a>
                        ) : (
                          <Link to={"/app/article/" + items["ARTICLE_ID"]}>
                            {items["TITLE"]}
                          </Link>
                        )}
                      </h3>
                      {/* {items["WRITER"] !== "" && (
                        <div className={cx("publisher")}>
                          <span className={cx("profile")}>
                            <img src={user_avata} alt="user-avata" />
                          </span>
                          {items["WRITER"]}
                        </div>
                      )} */}
                      <div className={cx("card-footer")}>
                        <span>{items["CREATE_DT"]}</span>
                        {items["ARTICLE_TYPE"] === "1" && (
                          <>
                            <span>
                              <img src={heart} alt="heart" />
                              {items["LIKE_CNT"]}
                            </span>
                            <span>
                              <img src={comment} alt="comment" />
                              {items["COMMENT_CNT"]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Report);
