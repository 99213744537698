import React from "react";
import classnames from "classnames/bind";
import styles from "./_RegisterDone.module.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const cx = classnames.bind(styles);

class RegisterDone extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={cx("content")}>
        <div className={cx("register")}>
          <div className={cx("register-left")}>
            <h1>{ReactHtmlParser(t("register.registerText"))}</h1>
            <p>{t("register.nowJoin")}</p>
            <div className={cx("clear")}></div>
          </div>
          <div className={cx("register-right")}>
            <div>
              <span>{t("register.regiDone")}</span>
              <h1>{t("register.congratulation")}</h1>
              <p>
                {t("register.memberOk")}
                <br />
                {t("register.cnsText")}
                <br />
                {t("register.supply")}
              </p>
              <Link to="/app" replace>
                {t("register.confirm")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RegisterDone);
