import React from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames/bind";
import { withCookies } from "react-cookie";
import styles from "./errorPage.scss";
import { withTranslation } from "react-i18next";

const cx = classNames.bind(styles);

class ErrorPage extends React.Component {
  render() {
    const { t } = this.props;
    let page = this.props.match.params.errorCode;
    if (!page) {
      page = "404";
    }
    return (
      <div className={cx("errorWrap")}>
        {page === "404" ? (
          <div>
            <h2>{t("errorPage.title")}</h2>
            <p>
              404 Not Found <br />
              {t("errorPage.404text")}
            </p>
            <Link to="/app">{t("errorPage.toMain")}</Link>
          </div>
        ) : (
          <div>
            <h2>{t("errorPage.title")}</h2>
            <p>
              506 ERROR <br />
              {t("errorPage.506text")}
            </p>
            <Link to="/app">{t("errorPage.toMain")}</Link>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withCookies(withRouter(ErrorPage)));
