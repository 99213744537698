import React from "react";
import classnames from "classnames/bind";
import styles from "./_Dictionary.module.scss";
import heart from "assets/images/heart.png";
import comment from "assets/images/comment.png";
import user_avata from "assets/images/user-avata.png";
import comic_avata from "assets/images/comic-avata.png";

const cx = classnames.bind(styles);

class Comic extends React.Component {
  state = {
    ArticleArr: []
  };

  async componentDidMount() {
    const data = await this.props.ArticleContent();

    let nextState = this.state;
    nextState["ArticleArr"] = data;
    this.setState(nextState);
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ArticleArr !== this.props.ArticleArr) {
      let State = this.state;
      State["ArticleArr"] = nextProps["ArticleArr"];
      this.setState(State);
      return true;
    }
    return false;
  }

  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push("/app" + item["ARTICLE_URL"]);
    } else {
      // window.open(item["ARTICLE_URL"], "_blank");
      window.ReactNativeWebView.postMessage("open|" + item["ARTICLE_URL"]);
    }
  };

  render() {
    return (
      <div className={cx("dictionary-wrap")}>
        <div className={cx("dictionary-contents")}>
          <div className={cx("contents-list")}>
            <div className={cx("comics")}>
              {this.state.ArticleArr.length > 0 &&
                this.state.ArticleArr.map((item, index) => (
                  <div
                    key={index}
                    className={cx("comics-content-wrap")}
                    onClick={e => this.goto(item)}
                  >
                    <div className={cx("comic-avata")}>
                      <img src={item["INFO_THUMB_URL"]} alt="avata" />
                    </div>
                    <div className={cx("comic-info")}>
                      <h4>{item.TITLE}</h4>
                      {item["SUMMARY"] && <p>{item["SUMMARY"]}</p>}
                      <div className={cx("publisher")}>{item.BRAND_NAME}</div>
                      <div className={cx("card-footer")}>
                        <span>{item.CREATE_DT}</span>
                        {this.state.ArticleArr.LIKE_CNT && (
                          <span>
                            <img src={heart} alt="heart" />
                            {this.state.ArticleArr.LIKE_CNT}
                          </span>
                        )}
                        {this.state.ArticleArr.COMMENT_CNT && (
                          <span>
                            <img src={comment} alt="comment" />
                            {this.state.ArticleArr.COMMENT_CNT}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Comic;
