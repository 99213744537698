import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";

import Popup from "components/common/Popup/Popup";
import Login from "containers/user/login/UserLoginContainer";

import Dashed from "assets/images/dashed.png";

import styles from "./comment.scss";
const cx = classnames.bind(styles);

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoading: true,
      RecommentVis: false,
      commentModiView: true,
      reComment: "",
      MESSAGE: "",
      SEQ: "",
      NICK_NAME: "",
      CREATE_DT: "",
      ARTICLE_ID: "",
      index: -1
    };
  }

  componentDidMount() {
    this.commentData();
  }

  openModal = e => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    this.setState({ isModalOpen: true });
  };

  closeModal = e => {
    this.setState({ isModalOpen: false });
  };

  commentData = () => {
    const {
      MESSAGE,
      SEQ,
      NICK_NAME,
      CREATE_DT,
      ARTICLE_ID,
      IS_EDITABLE,
      index
    } = this.props;
    let nextState = this.state;
    nextState["MESSAGE"] = MESSAGE;
    nextState["SEQ"] = SEQ;
    nextState["NICK_NAME"] = NICK_NAME;
    nextState["CREATE_DT"] = CREATE_DT;
    nextState["index"] = index;
    nextState["ARTICLE_ID"] = ARTICLE_ID;
    nextState["IS_EDITABLE"] = IS_EDITABLE;
    nextState["isLoading"] = false;
    this.setState(nextState);
  };

  showReComment = () => {
    let nextState = this.state;
    nextState["RecommentVis"] = !nextState["RecommentVis"];
    this.setState(nextState);
  };

  commentModiChange = e => {
    let nextState = this.state;
    nextState["MESSAGE"] = e.target.value;
    this.setState(nextState);
  };
  viewCommentModi = () => {
    let nextState = this.state;
    nextState["commentModiView"] = !this.state.commentModiView;
    this.setState(nextState);
  };

  AddreComment = e => {
    const { t } = this.props;
    e.preventDefault();
    let nextState = this.state;
    if (!this.props.isLogined) {
      this.openModal();
      return false;
    } else {
      this.props.AddreComment(
        this.state.ARTICLE_ID,
        this.state.reComment,
        parseInt(this.state.SEQ)
      );
      // window.location.reload();
      this.props.ContentDetail();
      this.props.callComments();
      nextState["reComment"] = "";
      nextState["RecommentVis"] = false;
      this.setState(nextState);
      this.props.closeRecomment();
    }
  };
  delComment = e => {
    const { t } = this.props;
    if (!this.props.isLogined) {
      this.openModal();
      return false;
    } else {
      this.props.delComment(this.state.ARTICLE_ID, parseInt(this.state.SEQ));
      this.props.ContentDetail();
      this.props.callComments();
    }
  };

  reComment = e => {
    let nextState = this.state;
    nextState["reComment"] = e.target.value;
    this.setState(nextState);
  };

  modiComment = () => {
    const { t } = this.props;
    const { ARTICLE_ID, MESSAGE, SEQ } = this.state;
    if (!this.props.isLogined) {
      this.openModal();
      return false;
    } else {
      this.props.modiComment(ARTICLE_ID, MESSAGE, SEQ);
      // window.location.reload();
      this.props.ContentDetail();
      this.props.callComments();
    }
    let nextState = this.state;
    nextState["commentModiView"] = true;
    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    if (this.state.isLoading) return "데이터를 불러오는 중입니다.";
    return (
      <>
        {this.state.commentModiView ? (
          <p className={cx("commentContent")}>{this.state.MESSAGE}</p>
        ) : (
          <input
            className={cx("commentModiInput")}
            type="text"
            name="commentModi"
            value={this.state.MESSAGE}
            onChange={e => this.commentModiChange(e)}
          />
        )}

        <>
          {this.state.RecommentVis ? (
            <button className={cx("replyBtn")} onClick={this.showReComment}>
              {t("comment.replyCancel")}
            </button>
          ) : (
            <>
              <button onClick={this.showReComment} className={cx("replyBtn")}>
                {t("comment.replyAdd")}
              </button>
              {this.state.IS_EDITABLE === "Y" && (
                <>
                  {this.state.commentModiView ? (
                    <button onClick={this.viewCommentModi}>
                      {t("comment.replyModi")}
                    </button>
                  ) : (
                    <button onClick={this.modiComment}>
                      {t("comment.replySave")}
                    </button>
                  )}
                  <button onClick={() => this.delComment(this.state.SEQ)}>
                    {t("comment.replyDel")}
                  </button>
                </>
              )}
            </>
          )}
        </>
        <div className={cx("comment-data")}>
          <span className={cx("nickname")}>{this.state.NICK_NAME}</span>
          <span className={cx("add-date")}>{this.state.CREATE_DT}</span>
        </div>

        <div className={cx("clear")}></div>
        {this.state.RecommentVis && (
          <div className={cx("re-comment")}>
            <div>
              <img src={Dashed} alt="dash" />
            </div>
            <div className={cx("add-re-comment")}>
              <form className={cx("add-comment")}>
                <input
                  type="text"
                  placeholder={
                    !this.props.isLogined
                      ? t("PostDetail.pleaseLogin")
                      : t("PostDetail.writeReply")
                  }
                  onChange={e => this.reComment(e)}
                />
                <button onClick={e => this.AddreComment(e)}>
                  {t("PostDetail.confirm")}
                </button>
              </form>
            </div>
          </div>
        )}
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
        >
          <Login
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          />
        </Popup>
      </>
    );
  }
}

export default withTranslation()(Comment);
