import React from "react";
import classnames from "classnames/bind";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import star_yellow from "assets/images/star_yellow.png";
import star_gray from "assets/images/Star_gray.png";

import styles from "./../_Main.module.scss";

const cx = classnames.bind(styles);

class FavHouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      favHouseList: [],
      favHouseList_len: "",
      HOUSE_URL: "",
      INFO_THUMB_URL: "",
      RENT_TYPE_NAME: "",
      HOUSE_TYPE_NAME: "",
      BRAND_NAME: "",
      HOUSE_NAME: "",
      ADDRESS: "",
      RENTFEE: "",
      HOUSE_ID: "",
      DEPOSIT: "",
      viewedHouse_len: 0,
      starView: false
    };
  }
  componentDidMount() {
    this.setListInfo();
  }
  setListInfo = () => {
    let nextState = this.state;
    nextState["HOUSE_URL"] = this.props.HOUSE_URL;
    nextState["INFO_THUMB_URL"] = this.props.INFO_THUMB_URL;
    nextState["RENT_TYPE_NAME"] = this.props.RENT_TYPE_NAME;
    nextState["HOUSE_TYPE_NAME"] = this.props.HOUSE_TYPE_NAME;
    nextState["BRAND_NAME"] = this.props.BRAND_NAME;
    nextState["HOUSE_NAME"] = this.props.HOUSE_NAME;
    nextState["ADDRESS"] = this.props.ADDRESS;
    nextState["RENTFEE"] = this.props.RENTFEE;
    nextState["DEPOSIT"] = this.props.DEPOSIT;
    nextState["HOUSE_ID"] = this.props.HOUSE_ID;
    nextState["favHouseList"] = this.props.favHouseList;
    nextState["favHouseList_len"] = this.props.favHouseList_len;
    nextState["viewedHouse_len"] = this.props.viewdhouse_len;
    nextState["starView"] = this.state.favHouseList.some(value => {
      if (value["HOUSE_ID"] === this.state.HOUSE_ID) {
        return true;
      } else {
        return false;
      }
    });
    nextState["isLoading"] = false;

    this.setState(nextState);
  };
  AddFav = (e, houseid) => {
    e.preventDefault();
    this.props.AddFav(houseid);
    let nextState = this.state;
    const { starView } = this.state;
    nextState["starView"] = !starView;
    this.setState(nextState);
  };
  DelFav = (e, houseid) => {
    e.preventDefault();
    this.props.DelFav(houseid);
    let nextState = this.state;
    const { starView } = this.state;
    nextState["starView"] = !starView;
    this.setState(nextState);
  };
  render() {
    const { t } = this.props;
    if (this.state.isLoading) return null;
    return (
      <div>
        <Link to={"/app" + this.state.HOUSE_URL}>
          <div>
            <div className={cx("image-box")}>
              <img
                src={this.state.INFO_THUMB_URL}
                alt={"room"}
                onError={this.onImageError}
              />
            </div>
            <div className={cx("types")}>
              <button>{this.state.RENT_TYPE_NAME}</button>
              <span>{this.state.HOUSE_TYPE_NAME}</span>
            </div>
            <div className={cx("title")}>
              {this.state.BRAND_NAME + " " + this.state.HOUSE_NAME}
            </div>
            <div className={cx("location")}>
              <span>{this.state.ADDRESS}</span>
            </div>
            <div className={cx("price")}>
              <p>
                <span>{t("common.rentFee")}</span>
                <span>{this.state.RENTFEE}</span>
              </p>
              <p>
                <span>{t("common.deposit")}</span>
                <span>{this.state.DEPOSIT}</span>
              </p>
            </div>
          </div>
        </Link>
        <div className={cx("star-wrap")}>
          {this.state.favHouseList_len > 0 ? (
            this.state.starView ? (
              <img
                src={star_yellow}
                alt={"star_yellow"}
                onClick={e =>
                  this.DelFav(
                    e,

                    this.state.HOUSE_ID
                  )
                }
              />
            ) : (
              <img
                src={star_gray}
                alt={"star_gray"}
                onClick={e =>
                  this.AddFav(
                    e,

                    this.state.HOUSE_ID
                  )
                }
              />
            )
          ) : (
            <img
              src={star_gray}
              alt={"star_gray"}
              onClick={e =>
                this.AddFav(
                  e,

                  this.state.HOUSE_ID
                )
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(FavHouse);
