import React from "react";
import classnames from "classnames/bind";
import styles from "./_InhabiMobile.module.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class InhabiMobile extends React.Component {
  goto = e => {
    window.location.href = e;
  };

  render() {
    const { t } = this.props;
    return (
      <div className={cx("m-inhabi")}>
        <div className={cx("content")}>
          <h2>{t("common.comeenstay")}
            {t("common.livingencyclo")}</h2>
          <Link
            to={{
              pathname: "/app/article/young",
              state: {
                nowCategory: "young"
              }
            }}
          >
            <div className={cx("young-search")}>
              <h3>
                {t("common.everythingofhouse")}
                <br />
                <span>{t("common.everymore")}</span>
              </h3>
              <p>
                {t("main.whatThat")}
                <br />
                {t("main.noCar")}
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/app/article/house",
              state: {
                nowCategory: "young",
                category: false
              }
            }}
          >
            <div className={cx("house-guide")}>
              <h3>
                {t("main.shareHouse")}
                <br />
                <span>{t("main.funLife")}</span>
              </h3>
              <p>
                {t("main.shareHouseAbout")}
                <br />
                {t("main.moreInfo")}
                <br />
                {t("main.toKnow")}
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/app/article/young/youngknow",
              state: {
                nowCategory: "young"
              }
            }}
          >
            <div className={cx("young-guide")}>
              <h3>
                {t("main.youngHome")}
                <br />
                <span>{t("main.youngHomeKnow")}</span>
              </h3>
              <p>
                {t("main.youngHouseAbout")}
                <br />
                {t("main.youngInfo")}
                <br />
                {t("main.moreKnow")}
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/app/article/house/comic",
              state: {
                nowCategory: "house",
                nowDetailCategory: "0002"
              }
            }}
          >
            <div className={cx("house-comic")}>
              <h3>
                {t("main.shareHouse")}
                <br />
                <span>{t("main.inLiveStory")}</span>
              </h3>
              <p>
                {t("main.titleVol")}
                <br />
                {t("main.situation")}
              </p>
            </div>
          </Link>
          <Link
            to={{
              pathname: "/app/article/house/story",
              state: {
                nowCategory: "house",
                nowDetailCategory: "0003"
              }
            }}
          >
            <div className={cx("house-story")}>
              <h3>
                {t("main.houseShare")}
                <br />
                <span>{t("main.story")}</span>
              </h3>
              <p>
                {t("main.sharePet")}
                <br />
                {t("main.minkyLife")}
              </p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(InhabiMobile);