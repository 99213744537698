import React from "react";
import classnames from "classnames/bind";
import styled from "./_Recently.module.scss";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import star_yellow from "assets/images/star_yellow.png";
import star_gray from "assets/images/Star_gray.png";
import plus_icon from "assets/images/Union.png";
import Image from "assets/images/default-image.png";
import check from "assets/images/check.png";

const cx = classnames.bind(styled);

class Recently extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favhouselist: [],
      favhouselist_len: 0,
      checked: [],
      checkedAll: false
    };
  }

  componentDidMount = async () => {
    this.favhouse();
  };

  favhouse = async () => {
    if (this.props.isLogined) {
      const data = await this.props.SeeFavHouse();
      if (data.houseList === undefined || data.houseList === null) return;
      let nextState = this.state;
      nextState["favhouselist"] = data.houseList;
      nextState["favhouselist_len"] = data.houseList.length;
      this.setState(nextState);
    }
  };

  onImageError = e => {
    e.currentTarget.src = Image;
  };

  goto = e => {
    this.props.history.push("/app" + e);
  };

  onChangeCheckBox = e => {
    let nextState = this.state;

    if (this.state.checkedAll) {
      nextState["checkedAll"] = false;
    }

    if (e.target.checked) {
      nextState.checked.push(e.target.value);
    } else {
      nextState.checked = nextState.checked.filter(
        check => check !== e.target.value
      );
    }

    this.setState(nextState);
  };

  onChangeCheckBoxAll = () => {
    if (this.state.checked.length !== this.props.viewdhouse_arr.length)
      this.setState({
        checked: this.props.viewdhouse_arr.map(item => item["HOUSE_ID"])
      });
    else
      this.setState({
        checked: []
      });
  };

  onChangefavoriteCheckBoxAll = () => {
    if (this.state.checked.length !== this.state.favhouselist.length)
      this.setState({
        checked: this.state.favhouselist.map(item => item["HOUSE_ID"])
      });
    else
      this.setState({
        checked: []
      });
  };

  onClickDel = () => {
    const checked = this.state.checked.join("|");
    const viewed = localStorage.getItem("viewdhouse").trim();
    if (viewed === "") return;

    localStorage.setItem(
      "viewdhouse",
      viewed
        .split("|")
        .filter(view => !checked.includes(view))
        .join("|")
    );
    window.location.reload();
  };

  onClickFavDel = async () => {
    let nextState = this.state;

    const promises = nextState["checked"].map(async item =>
      this.props.deleteFavHouse(item)
    );

    await Promise.all(promises);

    window.location.reload();
  };

  AddFav = async (e, houseid) => {
    if (!this.props.isLogined) {
      this.props.openModal();

      return false;
    } else {
      await this.props.AddFavHouse(houseid);
      await this.favhouse();
      return;
    }
  };

  DelFav = async (e, houseid, Y) => {
    if (!this.props.isLogined) {
      this.props.openModal();
      return false;
    } else {
      await this.props.deleteFavHouse(houseid);
      await this.favhouse();

      return;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className={cx("popularity")}>
          <div className={cx("content")}>
            {this.props.thispage === "recent" ? (
              <>
                <h2>
                  <input
                    type="checkbox"
                    id="chk-all"
                    checked={
                      this.state.checked.length !== 0 &&
                      this.state.checked.length ===
                        this.props.viewdhouse_arr.length
                    }
                    onClick={this.onChangeCheckBoxAll}
                    readOnly
                  />
                  <label htmlFor="chk-all">
                    <em>
                      <img src={check} alt={"check"} />
                    </em>
                    {this.props.viewdhouse_arr.length} {t("myPage.houseCount")}
                  </label>
                </h2>
                <button onClick={this.onClickDel}>{t("myPage.Del")}</button>
                {/* 아래는 최근본 하우스가 존재할경우 나오는 컨탠츠들 */}
                <div className={cx("recently-viewed-items")}>
                  {this.props.viewdhouse_arr ? (
                    this.props.viewdhouse_arr.map((items, index) => {
                      return (
                        <div key={items["HOUSE_ID"]}>
                          <div onClick={() => this.goto(items["HOUSE_URL"])}>
                            <div className={cx("image-box")}>
                              <img
                                src={items["INFO_THUMB_URL"]}
                                alt={"room"}
                                onError={this.onImageError}
                              />
                            </div>
                            <div className={cx("types")}>
                              <button>{items["RENT_TYPE_NAME"]}</button>
                              <span>{items["HOUSE_TYPE_NAME"]}</span>
                            </div>
                            <div className={cx("title")}>
                              <Link to={"/app" + items["HOUSE_URL"]}>
                                {items["BRAND_NAME"] +
                                  " " +
                                  items["HOUSE_NAME"]}
                              </Link>
                            </div>
                            <div className={cx("location")}>
                              <span>{items["ADDRESS"]}</span>
                            </div>
                            <div className={cx("price")}>
                              <p>
                                <span>{t("myPage.monthPay")}</span>
                                <span>{items["RENTFEE"]}</span>
                              </p>
                              <p>
                                <span>{t("myPage.deposit")}</span>
                                <span>{items["DEPOSIT"]}</span>
                              </p>
                            </div>
                          </div>
                          {items["IS_FAVORITED_YN"] === "Y" ? (
                            <img
                              src={star_yellow}
                              alt={"star_yellow"}
                              onClick={e => this.DelFav(e, items["HOUSE_ID"])}
                            />
                          ) : (
                            <img
                              src={star_gray}
                              alt={"star_gray"}
                              onClick={e => this.AddFav(e, items["HOUSE_ID"])}
                            />
                          )}
                          <h3>
                            <input
                              type="checkbox"
                              className={cx("chk-box")}
                              id={"chk-piece" + index}
                              onChange={this.onChangeCheckBox}
                              value={items["HOUSE_ID"]}
                              checked={
                                this.state.checked.includes(
                                  items["HOUSE_ID"]
                                ) || this.state.checkedAll
                              }
                            />
                            <label htmlFor={"chk-piece" + index}>
                              <em>
                                <img src={check} alt={"check"} />
                              </em>
                            </label>
                          </h3>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className={cx("more-house")}>
                        <div className={cx("more-content")}>
                          <span>{t("myPage.moreHouse")}</span>
                          <button>
                            <img src={plus_icon} alt="plus" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              // thismypage 가 interest 일때!
              <>
                <h2>
                  <input
                    type="checkbox"
                    id="chk-all"
                    checked={
                      this.state.checked.length !== 0 &&
                      this.state.checked.length ===
                        this.state.favhouselist.length
                    }
                    onClick={this.onChangefavoriteCheckBoxAll}
                  />
                  <label htmlFor="chk-all">
                    <em>
                      <img src={check} alt={"check"} />
                    </em>
                    {this.state.favhouselist_len} {t("myPage.houseCount")}
                  </label>
                </h2>
                <button onClick={this.onClickFavDel}>{t("myPage.Del")}</button>
                <div className={cx("recently-viewed-items")}>
                  {this.state.favhouselist_len > 0 ? (
                    this.state.favhouselist.map((items, index) => {
                      return (
                        <div key={items["HOUSE_ID"]}>
                          <div onClick={() => this.goto(items["HOUSE_URL"])}>
                            <input
                              type="hidden"
                              value={items["HOUSE_ID"]}
                              name="houseId"
                              readOnly
                            />
                            <div className={cx("image-box")}>
                              <img
                                src={items["INFO_THUMB_URL"]}
                                alt={"room"}
                                onError={this.onImageError}
                              />
                            </div>
                            <div className={cx("types")}>
                              <button>{items["RENT_TYPE_NAME"]}</button>
                              <span>{items["HOUSE_TYPE_NAME"]}</span>
                            </div>
                            <div className={cx("title")}>
                              <Link to={"/app" + items["HOUSE_URL"]}>
                                {items["BRAND_NAME"] +
                                  " " +
                                  items["HOUSE_NAME"]}
                              </Link>
                            </div>
                            <div className={cx("location")}>
                              <span>{items["ADDRESS"]}</span>
                            </div>
                            <div className={cx("price")}>
                              <p>
                                <span>{t("myPage.monthPay")}</span>
                                <span>{items["RENTFEE"]}</span>
                              </p>
                              <p>
                                <span>{t("myPage.deposit")}</span>
                                <span>{items["DEPOSIT"]}</span>
                              </p>
                            </div>
                          </div>
                          <img
                            src={star_yellow}
                            alt={"star_yellow"}
                            onClick={e =>
                              this.DelFav(e, items["HOUSE_ID"], "Y")
                            }
                          />
                          <h3>
                            <input
                              type="checkbox"
                              className={cx("chk-box")}
                              id={"chk-piece" + index}
                              onChange={this.onChangeCheckBox}
                              value={items["HOUSE_ID"]}
                              checked={
                                this.state.checked.includes(
                                  items["HOUSE_ID"]
                                ) || this.state.checkedAll
                              }
                            />
                            <label htmlFor={"chk-piece" + index}>
                              <em>
                                <img src={check} alt={"check"} />
                              </em>
                            </label>
                          </h3>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div
                        className={cx("more-house")}
                        onClick={() => this.props.history.push("/app/search")}
                      >
                        <div className={cx("more-content")}>
                          <span>{t("myPage.moreHouse")}</span>
                          <button>
                            <img src={plus_icon} alt="plus" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Recently));
