import React from "react";
import Header from "components/common/Header";
import { withCookies } from "react-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as UserActions from "stores/modules/user";

class HeaderContainer extends React.Component {
  // 헤더 콘테이너
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Logout = async e => {
  //   e.preventDefault();
  //   await this.props.UserActions.Logout();
  //   await this.props.cookies.remove("auth", { path: "/" });
  //   await this.props.history.replace("/");
  //   return;
  // };

  render() {
    return (
      // 헤더 컴포넌트 반환
      <Header
        member_name={this.props.member_name}
        // Logout={this.Logout}
        isLogined={this.props.isLogined}
        openModal={this.props.openModal}
        closeModal={this.props.closeModal}
        isModalOpen={this.props.isModalOpen}
        HeaderFixed={this.props.HeaderFixed}
      />
    );
  }
}

export default withCookies(
  connect(
    state => ({
      isLogined: state.user.auth.user.isLogined,
      member_name: state.user.auth.user.member_name
    }),
    dispatch => ({
      UserActions: bindActionCreators(UserActions, dispatch)
    })
  )(withRouter(HeaderContainer))
);
