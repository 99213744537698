import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import client from "lib/client";
import qs from "querystring";

const SEARCHELP = "SERCHELP";
const ADDCOMPARE = "ADDCOMPARE";
const SEARCH_LIST = "SEARCH_LIST";
const COMPARELIST = "COMPARE";
const HOUSEDETAIL = "HOUSEDETAIL";
const SEARCH_WORD = "SEARCH_WORD";

// ---------------------------------------------------------------

// -------- 검색 추천 키워드
const SearchHelp_API = search_keyword => {
  return client.get(`/rest/search/word?SEARCH_TEXT=${search_keyword}`);
};

// -------- 하우스 검색 리스트
const SearchList_API = (
  {
    page,
    rows,
    NORTH,
    SOUTH,
    EAST,
    WEST,
    SHAREHOUSE,
    HOUSE2030,
    PREMIUM_HOUSE,
    VR_HOUSE,
    NEW_OPEN,
    NOW_MOVIN,
    CAMPAIGN,
    GENDER_WOMAN,
    GENDER_MAN,
    GENDER_ALL,
    FOREIGNER,
    DEPOSIT_ADJUST,
    FAT,
    DEPOSIT_MIN,
    DEPOSIT_MAX,
    RENTFEE_MIN,
    RENTFEE_MAX
  } = { DEPOSIT_MIN: "", DEPOSIT_MAX: "", RENTFEE_MIN: "", RENTFEE_MAX: "" }
) => {
  return client.get(
    `/rest/house?${qs.stringify({
      page,
      rows,
      NORTH,
      SOUTH,
      EAST,
      WEST,
      SHAREHOUSE,
      HOUSE2030,
      PREMIUM_HOUSE,
      VR_HOUSE,
      NEW_OPEN,
      NOW_MOVIN,
      CAMPAIGN,
      GENDER_WOMAN,
      GENDER_MAN,
      GENDER_ALL,
      FOREIGNER,
      DEPOSIT_ADJUST,
      FAT,
      DEPOSIT_MIN,
      DEPOSIT_MAX,
      RENTFEE_MIN,
      RENTFEE_MAX
    })}`
  );
};

// 비교함 추가
const AddCompare_API = houseid => {
  return client.post(`/rest/compare/house/${encodeURIComponent(houseid)}`);
};

const CompareList_API = () => {
  return client.get("/rest/compare/house");
};

// 해당하우스의 상세정보 조회
const HouseDetail_API = houseid => {
  return client.get(`/rest/${houseid}`);
};

// ---------------------------------------------------------------

export const SearchWord = createAction(SEARCH_WORD);
export const HouseDetail = createAction(HOUSEDETAIL, HouseDetail_API); // 하우스 상세 정보 조회
export const CompareList = createAction(COMPARELIST, CompareList_API); // 비교함 하우스 목록
export const AddCompare = createAction(ADDCOMPARE, AddCompare_API); // 비교함 추가
export const SearchHelp = createAction(SEARCHELP, SearchHelp_API); // 검색 추천 키워드
export const SearchList = createAction(SEARCH_LIST, SearchList_API); // 하우스 검색 리스트

const initialState = {
  SEARCH_TEXT: ""
};

export default handleActions(
  {
    [SEARCH_WORD]: (state, action) => {
      let nextState = state;
      const SEARCH_TEXT = action.payload;
      nextState["SEARCH_TEXT"] = SEARCH_TEXT;
      return nextState;
    },
    ...pender({
      type: SEARCHELP,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: SEARCH_LIST,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: ADDCOMPARE,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: COMPARELIST,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: HOUSEDETAIL,
      onSuccess: () => {
        return;
      }
    })
  },
  initialState
);
