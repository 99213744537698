import MainTemplate from "components/base/MainTemplate";
import ErrorPage from "components/common/errorPage";
import Intro from "components/company/intro/Intro";
import Privacy from "components/company/privacy/Privacy";
import Terms from "components/company/terms/Terms";
import ExpiredEmail from "components/user/ExpiredEmail";
import RegisterDone from "components/user/RegisterDone";
import Dictionary from "containers/common/dictionary/detail/DictinaryContainer";
import Main from "containers/common/main/MainContainer";
// import Dictionary from "components/dictionary";
import DictionaryPost from "containers/dictionary/PostDetailContainer";
import Detail from "containers/house/housecontainer";
import Search from "containers/search/SearchContainer";
import DeclinedEmail from "containers/user/DeclinedEmailContainer/DeclinedEmailContainer";
// import InquiryDetail from "components/user/mypage/details/Inquiry/detail/InquiryDetail";
import InquiryDetailContainer from "containers/user/inquiry/inquiryDetailContainer";
// import EmailCheck from "containers/user/emailCheck";
import EmailOk from "containers/user/mailOkContainer/MailOkContainer";
import Mypage from "containers/user/mypage/MypageContainer";
import PasswordEmailCheck from "containers/user/PasswordEmailCheckContainer/PasswordEmailCheckContainer";
import PasswordFind from "containers/user/passwordfind/PasswordFindContainer";
import Register from "containers/user/register/UserRegsterContainer";
import SNSLoginContainer from "containers/user/sns/SNSLoginContainer";
import i18n from "lang/i18n";
import client from "lib/client";
import { decode, encode } from "lib/encrypt";
import React from "react";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as LangActions from "stores/modules/lang";
import * as UserActions from "stores/modules/user";
import "./App.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      member_name: "",
      created: false
    };
  }
  async componentDidMount() {

    const MyStorage = localStorage;
    
    // 앱 시작시 쿠키값 및 시작 메시지 보내기
    try{
      window.ReactNativeWebView.postMessage("startApp");
      window.ReactNativeWebView.postMessage("startCookie");
    }catch(e){
    }

    // localStorage cookie 값 설정
    window.SetCookie = async (token) => {
      try {

        if(token === undefined || token === null){
          throw new Error('');
        }
        // await this.props.cookies.remove("auth", { path: "/app" });
        MyStorage.removeItem("auth");

        // await this.props.cookies.set("auth", token, { path: "/app" });

        MyStorage.setItem("auth", token)

        window.ReactNativeWebView.postMessage("sendConsole|" + token);

      } catch(e){
        // await this.props.cookies.remove("auth", { path: "/app" });
        MyStorage.removeItem("auth");
        window.ReactNativeWebView.postMessage("loginError");
      } finally {
        window.ReactNativeWebView.postMessage("finishCookie");
      }
    }

    // localStorage에 fcm 값 등록
    window.AddFcm = data => {
      window.FcmToken = data;
      MyStorage.setItem("deviceToken", data);
    }
    
    window.Fcm = datas => {
      const data = datas.split("|");
      window.FcmToken = data[1];
      MyStorage.setItem("deviceToken", data[1]);
      // window.ReactNativeWebView.postMessage("startAppFCM");
      // window.ReactNativeWebView.postMessage(`sendAlert|${window.FcmToken}`);
      this.Device_Regist(data);
    };

    // 로그아웃 메세지
    window.Logout = async () => {
      await this.Logout();
    };

    await this.language();
    
    this.Session_Refresh();
    
    // 쿠키보내기
    window.SendCookie = async () => {
      // let token = this.props.cookies.get("auth");
      const MyStorage = localStorage;
      let token = MyStorage.getItem("auth");
      if (token === null || token === undefined && token === "") token = "";
      
      window.ReactNativeWebView.postMessage("cookie|" + "auth=" + token);
    };
    
    // 언어 변경
    window.ChangeLang = async lang => {
      if (lang === "ko-KR") {
        await this.lang_ko();
      } else if (lang === "en-US") {
        await this.lang_en();
      }
    };

    // 
    window.delSearchWord = async () => {
        const MyStorage = localStorage;
        MyStorage.setItem("searchWord", "");
    }
  }

  // 새로고침시
  language = async () => {
    const { LangActions } = this.props;
    const myStorage = window.localStorage;

    const lang = myStorage.getItem("i18nextLng");

    if (lang === "en-US") {
      await LangActions.Lang_En();
    } else {
      await LangActions.Lang_Ko();
    }

    return;
  };

  // 한글로 변경
  lang_ko = async () => {
    const { i18n, LangActions } = this.props;

    i18n.changeLanguage("ko-KR");
    LangActions.Lang_Ko();
    const url = this.props.location.pathname;
    window.location.replace(url);

    return;
  };

  // 영어로 변경
  lang_en = async () => {
    const { i18n, LangActions } = this.props;

    i18n.changeLanguage("en-US");
    LangActions.Lang_En();
    const url = this.props.location.pathname;
    window.location.replace(url);

    return;
  };

  // 디바이스 등록 api 요청
  Device_Regist = async data => {
    try {
      const { UserActions } = this.props;
      const deviceType = data[0];
      const deviceToken = data[1];
      const deviceModel = data[2];
      const oldDeviceToken = data[3];
      const osVersion = data[4];
      const appVersion = data[5];

      const res = await UserActions.DeviceRegist({
        deviceType,
        deviceToken,
        deviceModel,
        oldDeviceToken,
        osVersion,
        appVersion
      });

      window.ReactNativeWebView.postMessage("finishRegist");

    } catch (e) {
      console.log(e);
      window.ReactNativeWebView.postMessage("finishRegist");
    }
  };

  Session_Refresh = async () => {
    const MyStorage = localStorage;
    const { t } = this.props;
    try {
      // const token = this.props.cookies.get("auth");
      const token = MyStorage.getItem('auth');

      if (token !== undefined && token !== null && token !== "") {
        if (token.trim() === "") {
          throw new Error('');
          // this.props.cookies.remove("auth");
          // this.props.history.replace("/app");
          // return;
        }
        const refresh_token = JSON.parse(decode(token))["refresh_token"];

        const res = await this.props.UserActions.Session_Refresh({
          refresh_token
        });

        if (res["data"]["result"]["resCode"] !== "0000") {
          throw new Error('');
          // alert(t("main.loginAlert"));
          // window.ReactNativeWebView.postMessage(
          //   "sendAlert|" + t("main.loginAlert")
          // );
          // await this.Logout();
          // return false;
        }

        let LoginData = {
          member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
          access_token: res.data.result.data["ACCESS_TOKEN"],
          refresh_token
        };

        LoginData = encode(JSON.stringify(LoginData));

        // this.props.cookies.set("auth", LoginData, { path: "/app" });
        MyStorage.setItem('auth', LoginData);

        window.ReactNativeWebView.postMessage("login|" + LoginData);
      }
    } catch (e) {
      await this.Logout();
      // alert(t("main.loginAlert"));
      window.ReactNativeWebView.postMessage(
        "sendAlert|" + t("main.loginAlert")
      );
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  Logout = async () => {
    const MyStorage = localStorage;
    // await this.props.cookies.remove("auth");
    // await this.props.UserActions.Logout();
    // await this.props.history.push("/app");
    // await this.props.cookies.set("auth", "");
    // this.props.cookies.remove("auth", { path: "/app" }); 
    // MyStorage.setItem('auth', LoginData);
    
    this.props.UserActions.Logout().then((value) => {
      window.ReactNativeWebView.postMessage("loginError");
      window.ReactNativeWebView.postMessage("close");
      MyStorage.setItem('auth', "");
      // this.props.cookies.set("auth", "", { path: "/app" });

      this.props.history.push('/app');
    });
    // window.location.replace('/app');
    return;
  };

  // 인이 되어있을때
  PrivateRoute = ({ component: Component, ...other }) => {
    const { isLogined } = this.props;
    const { isLoading } = this.state;

    return (
      <Route
        {...other}
        render={props => {
          if (isLoading) return null;

          if (isLogined)
            return (
              <Redirect
                to={{
                  pathname: "/app"
                }}
              />
            );

          return <Component {...props} />;
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) return null;
    return (
      <BrowserRouter htmlAttributes={{ lang: i18n.language }}>
        <MainTemplate thisPath={this.props.location.pathname}>
          <Switch>
            {/* 메인페이지 */}
            <Route exact path="/app" component={Main} />

            {/* 일반회원 회원가입 페이지 */}
            <Route exact path="/app/user/register" component={Register} />

            <Route //일반회원 회원가입 완료페이지
              exact
              path="/app/user/registerdone"
              component={RegisterDone}
            />

            <Route
              exact
              path="/app/user/passwordfind"
              component={PasswordFind}
            />

            {/* 하우스검색 페이지 */}
            <Route exact path="/app/search" component={Search} />

            <Route // 하우스 상세페이지
              exact
              path="/app/house/:house"
              component={Detail}
            />

            {/* 청년주거백과 */}
            <Route exact strict path="/app/article" component={Dictionary} />
            {/* 청년주거백과 자체제작 게시물 상세페이지 */}

            <Route
              exact
              strict
              path="/app/article/house"
              component={Dictionary}
            />
            <Route
              exact
              strict
              path="/app/article/young"
              component={Dictionary}
            />
            <Route
              exact
              strict
              path="/app/article/house/:sub(comic|houselife|story|report)"
              component={Dictionary}
            />
            <Route
              exact
              strict
              path="/app/article/young/:type(youngknow)"
              component={Dictionary}
            />

            <Route exact path="/app/article/:type" component={DictionaryPost} />

            {/* 마이페이지 */}
            <Route exact path="/app/user/mypage" component={Mypage} />
            <Route exact path="/app/user/mypage/:type" component={Mypage} />

            <Route
              exact
              path="/app/user/mypage/inquiry/detail/:inquiry_id"
              component={InquiryDetailContainer}
            />

            {/* 이메일 체크 페이지 */}
            {/* <Route exact path="/account/confirmEmail/:email" component={EmailCheck} /> */}

            {/* 이메일 패스워드 변경 페이지 */}
            <Route
              exact
              path="/app/account/resetPassword/:emailKey"
              component={PasswordEmailCheck}
            />

            {/* 이메일인증 승인됨 */}
            {/* <Route exact path="/account/confirmEmail/:confirmId " component={EmailOk} /> */}
            <Route
              exact
              path="/app/account/confirmEmail/:confirmId"
              component={EmailOk}
            />
            <Route
              exact
              path="/app/account/refuseConfirm/:confirmId"
              component={DeclinedEmail}
            />
            {/* 패스워드 변경 이메일 연동 페이지 */}
            <Route
              exact
              path="/app/user/passwordemailcheck/:emailKey"
              // path="/account/resetPassword/:emailKey"
              component={PasswordEmailCheck}
            />

            <Route //이메일 인증 기간 만료됨
              exact
              path="/app/user/expiredemail"
              component={ExpiredEmail}
            />

            {/* 컴앤스테이 회사소개 */}
            <Route exact path="/app/company/intro" component={Intro} />

            {/* 컴앤스테이 이용약관 */}
            <Route exact path="/app/company/terms" component={Terms} />

            {/* 개인정보 취급정보 방침 */}
            <Route exact path="/app/company/privacy" component={Privacy} />

            {/* TODO: 없는 페이지 표시 수정 */}
            <Route exact path="/app/error/:errorCode" component={ErrorPage} />

            <Route
              exact
              path="/app/sns/:type/oauth/"
              component={SNSLoginContainer}
            />

            <Route component={ErrorPage} />
          </Switch>
        </MainTemplate>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(
  withCookies(
    connect(
      state => ({
        isLogined: state.user.auth.user.isLogined
      }),
      dispatch => ({
        UserActions: bindActionCreators(UserActions, dispatch),
        LangActions: bindActionCreators(LangActions, dispatch)
      })
    )(withRouter(App))
  )
);
