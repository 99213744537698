import React from "react";
import Main from "components/common/Main";
import { withCookies } from "react-cookie";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as MainAction from "stores/modules/main";
import * as SearchAction from "stores/modules/search";

import { resCode } from "lib/resCode";

class MainContainer extends React.Component {
  // 인덱스 페이지 ( 메인 ) 콘테이너
  constructor(props) {
    super(props);
    this.state = {
      todayHouse_len: 0,
      todayHouseArr: [],
      viewdhouse_arr: [],
      keyword_list: [],
      bannerList: [],
      viewdhouse_len: 0,
      isLoading: false,
      ishLoading: false,
      mainLoading: true,
      bannerLoading: true
    };
  }

  componentDidMount() {
    this.bannerListGet();
    this.TodayHouse();
    this.ViewdHouse();
  }

  SearchHouse = (keyword, filter) => {
    this.props.history.push("/app/search", { keyword, filter });
  };

  SearchHelp = async search_keyword => {
    if (!search_keyword) return false;

    const { SearchAction } = this.props;
    try {
      const res = await SearchAction.SearchHelp(search_keyword);

      const data = resCode(res);

      let nextState = this.state;

      nextState["keyword_list"] = data.kewordList;
      this.setState(nextState);

      return;
    } catch (e) {
      console.log(e);
    }
  };

  // 오늘의 인기하우스 api 통신후 하우스 리스트 반환하는 함수
  TodayHouse = async () => {
    const { MainAction } = this.props;

    try {
      const res = await MainAction.TodayHouse();

      let nextState = this.state;

      nextState["todayHouseArr"] = res.data.result.data.houseList;
      nextState["todayHouse_len"] = res.data.result.data.houseList.length;
      nextState["isLoading"] = true;
      nextState["mainLoading"] = false;

      this.setState(nextState);
      return;
    } catch (e) {
      // 통신중 오류가 생긴다면
      console.log(e);
    }
  };

  // 최근본 하우스
  ViewdHouse = async () => {
    const { MainAction } = this.props;
    const myStorage = localStorage;
    const viewdhouse = myStorage.getItem("viewdhouse");
    try {
      const res = await MainAction.ViewdHouse(viewdhouse);

      let nextState = this.state;

      nextState["viewdhouse_arr"] = res.data.result.data.houseList;
      nextState["viewdhouse_len"] = res.data.result.data.houseList.length;
      nextState["ishLoading"] = true;

      this.setState(nextState);

      return;
    } catch (e) {
      // 통신중 오류가 생긴다면
      console.log(e);
    }
  };

  // 관심하우스 등록 ( 하우스 썸네일에 별모양 누르면 됨. )
  AddFavHouse = async houseid => {
    try {
      const res = await this.props.MainAction.AddFavHouse(houseid);
      await resCode(res);
    } catch (e) {
    } finally {
      this.TodayHouse();
      this.ViewdHouse();
      this.SeeFavHouse();
    }
  };

  deleteFavHouse = async houseid => {
    const { MainAction } = this.props;
    try {
      const res = await MainAction.delFavHouse(houseid);
      resCode(res);
    } catch (e) {
    } finally {
      this.TodayHouse();
      this.ViewdHouse();
      this.SeeFavHouse();
    }
  };

  // 나의 관심하우스 조회용 ... 관심하우스면 별 색칠 해주는 용도로 도 들어가 있음.
  SeeFavHouse = async () => {
    const { MainAction } = this.props;
    try {
      const res = await MainAction.SeeFavHouse();
      if (res.data.result.resCode === "0000") {
        return res.data.result.data;
      }

      return null;
    } catch (e) {
      console.log(e);
    }
  };

  // 이벤트 베너 리스트 가져오기
  bannerListGet = async () => {
    const { MainAction } = this.props;
    let nextState = this.state;
    try {
      const res = await MainAction.getBannerList();
      if (res.data.result.resCode === "0000") {
        nextState["bannerList"] = res.data.result.data.bannerList;
        nextState["bannerLoading"] = false;
        this.setState(nextState);
      }
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    if (this.state.bannerLoading) return null;
    return (
      <Main
        isLogined={this.props.isLogined}
        SearchHouse={this.SearchHouse}
        TodayHouse={this.TodayHouse}
        ViewdHouse={this.ViewdHouse}
        SearchHelp={this.SearchHelp}
        AddFavHouse={this.AddFavHouse}
        SeeFavHouse={this.SeeFavHouse}
        deleteFavHouse={this.deleteFavHouse}
        todayHouseArr={this.state.todayHouseArr}
        todayHouse_len={this.state.todayHouse_len}
        viewdhouse_arr={this.state.viewdhouse_arr}
        viewdhouse_len={this.state.viewdhouse_len}
        keyword_list={this.state.keyword_list}
        bannerList={this.state.bannerList}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withCookies(
  connect(
    state => ({
      isLogined: state.user.auth.user.isLogined
    }),
    dispatch => ({
      MainAction: bindActionCreators(MainAction, dispatch),
      SearchAction: bindActionCreators(SearchAction, dispatch)
    })
  )(withRouter(MainContainer))
);
