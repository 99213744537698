import React, { createRef } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import ReactHtmlParser from "react-html-parser";
import Calendar from "react-calendar";
import moment from "moment";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import vr_see from "assets/images/vr-see.png";
import star_yellow from "assets/images/star_yellow.png";
import star_gray from "assets/images/Star_gray.png";
import folder_add from "assets/images/folder_add.png";
import share from "assets/images/share.png";
import facebook_share from "assets/images/facebook_share.png";
import kakao_share from "assets/images/kakao_share.png";
import twitter_share from "assets/images/twitter_share.png";
import link_share from "assets/images/link_share.png";

import styles from "./_MobileDetailMenu.module.scss";

import Login from "containers/user/login/UserLoginContainer";
import Popup from "components/common/Popup/Popup";
import copy from "copy-to-clipboard";

const cx = classnames.bind(styles);

class MobileDetailMenu extends React.Component {
  constructor(props) {
    super(props);
    this.mChatScroll = createRef();
    this.state = {
      isLoading: true,
      messageList: [],
      checkInCalendar: false,
      checkOutCalendar: false,
      calendarDate: "",
      checkInDate: "",
      checkOutDate: "",
      questionOn: false,
      InqMessage: "",
      HOUSE_ID: "",
      isLoginModalOpen: false,
    };
  }

  closeLoginModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  async componentDidMount() {
    await this.houseMessageList();

    // window.Kakao.cleanup();
    // window.Kakao.init("7948b1ade860cba5b7eb0c9d44af8b2f"); // 사용할 앱의 JavaScript 키를 설정

    // this.makeShareButton();

    document.addEventListener("click", (e) => {
      if (this.state.checkInCalendar) {
        const ps = $(e.target).parents();

        for (let index = 0; index < ps.length; index++) {
          const item = ps[index];
          const id = $(item).attr("id");
          const classes = $(item).attr("class");

          if (id === "calendar") {
            return;
          }
          if (classes === "react-calendar__century-view") {
            return;
          }
          if (classes === "react-calendar__decade-view") {
            return;
          }
          if (classes === "react-calendar__year-view") {
            return;
          }
        }
        this.setState({ checkInCalendar: false });
      } else if (this.state.checkOutCalendar) {
        const ps = $(e.target).parents();

        for (let index = 0; index < ps.length; index++) {
          const item = ps[index];
          const id = $(item).attr("id");
          const classes = $(item).attr("class");

          if (id === "calendar") {
            return;
          }
          if (classes === "react-calendar__century-view") {
            return;
          }
          if (classes === "react-calendar__decade-view") {
            return;
          }
          if (classes === "react-calendar__year-view") {
            return;
          }
        }
        this.setState({ checkOutCalendar: false });
      } else {
        this.setState({ checkInCalendar: false, checkOutCalendar: false });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messageList.length !== this.props.messageList) {
      if (this.mChatScroll.current) {
        this.mChatScroll.current.scrollTo({
          left: 0,
          top: this.mChatScroll.current.scrollHeight,
        });
      }
    }
  }

  houseMessageList = () => {
    const { messageList, fromDate, toDate } = this.props;
    let nextState = this.state;
    nextState["messageList"] = messageList;
    nextState["checkInDate"] = fromDate;
    nextState["checkOutDate"] = toDate;
    nextState["HOUSE_ID"] = this.props.HOUSE_ID;
    nextState["isLoading"] = false;
    this.setState(nextState);
  };
  houseMessageSend = async (e) => {
    e.preventDefault();

    const { HOUSE_ID, InqMessage, checkInDate, checkOutDate } = this.state;

    const { HouseInquery, getHouseDetail, t } = this.props;

    const INQUIRY_ID = this.props.states.inquiry.INQUIRY_ID;

    if (InqMessage === "") {
      // alert(t("inform.messageAlert"));
      window.ReactNativeWebView.postMessage(
        "sendAlert|" + t("inform.messageAlert")
      );
      return;
    }

    await HouseInquery({
      HOUSE_ID,
      MESSAGE: InqMessage,
      INQUIRY_ID,
      FROM_DATE: checkInDate,
      TO_DATE: checkOutDate,
    });

    const res = await getHouseDetail();

    let nextState = this.state;

    nextState["InqMessage"] = "";
    nextState["messageList"] = res.messageList;

    this.setState(nextState, () => {
      const scrollHeight = this.mChatScroll.current.scrollHeight;
      this.mChatScroll.current.scrollTo(0, scrollHeight);
    });
  };

  // 카카오 공유 버튼
  makeShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    let _address = this.props.states.house["ADDRESS"];
    let _housename = this.props.states.house["HOUSE_NAME"];
    // let _introduce = ReactHtmlParser(this.props.states.brand["INTRODUCE"]);
    let _brandname = this.props.states.brand.BRAND_NAME;
    let _introduce = this.props.states.brand["INTRODUCE"];
    let _houseid = this.props.states.house["HOUSE_ID"];
    let _imgurl = this.props.states.pictureList[0]["PIC_URL"];
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "kakaoHouseShare",
        address: _address,
        housename: _housename,
        introduce: _introduce,
        imgurl: _imgurl,
        brandname: _brandname,
        page,
        // `kakaoHouseShare|address|${_address}|housename|${_housename}|introduce|${_introduce}|page|${page}`
      })
    );
    // window.Kakao.Link.createDefaultButton({
    //   container: "#kakao",
    //   objectType: "location",
    //   address: `${_address} ${_housename}`,
    //   addressTitle: `${_address} ${_housename}`,
    //   content: {
    //     title: `${_housename}`,
    //     description: `${_introduce}`,
    //     imageUrl: `${_imgurl}`,
    //     link: {
    //       mobileWebUrl: page,
    //       webUrl: page
    //     }
    //   },
    //   social: {
    //     likeCount: 286,
    //     commentCount: 45,
    //     sharedCount: 845
    //   },
    //   buttons: [
    //     {
    //       title: "웹으로 보기",
    //       link: {
    //         mobileWebUrl: page,
    //         webUrl: page
    //       }
    //     }
    //   ]
    // });
  };

  facebookShareButton = () => {
    // const houseID = this.props.states.house.HOUSE_ID;
    // let hostname = window.location.origin;
    // const page = encodeURIComponent(hostname + "/house/" + houseID);
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    document.getElementById("og:url").setAttribute("content", page);
    document
      .getElementById("og:title")
      .setAttribute(
        "content",
        this.props.states.brand.BRAND_NAME +
          " " +
          this.props.states.house.HOUSE_NAME
      );
    document
      .getElementById("og:description")
      .setAttribute(
        "content",
        this.props.states.brand.BRAND_NAME +
          " " +
          this.props.states.house.HOUSE_NAME
      );
    document
      .getElementById("og:image")
      .setAttribute("content", this.props.states.brand.LOGO_PIC_URL);

    // window.open(
    //   `https://www.facebook.com/sharer/sharer.php?href=${page}`,
    //   "_blank"
    // );
    window.ReactNativeWebView.postMessage(
      "open|" + `https://www.facebook.com/sharer/sharer.php?href=${page}`
    );
  };

  twitterShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    window.ReactNativeWebView.postMessage(
      "open|" + `https://twitter.com/intent/tweet?url=${page}`
    );
  };

  normalShareLink = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    copy(page);
  };

  MessageChn = (e) => {
    let nextState = this.state;
    nextState["InqMessage"] = e.target.value;
    this.setState(nextState);
  };
  questionView = () => {
    if (!this.props.isLogined) {
      this.setState({ isLoginModalOpen: true });
      return;
    }
    let nextState = this.state;
    nextState["questionOn"] = true;
    this.setState(nextState, () => {
      this.props.onMobileChat(true);
      this.chatBtn.style.display = "none";
      const scrollHeight = this.mChatScroll.current.scrollHeight;
      this.mChatScroll.current.scrollTo(0, scrollHeight);
    });
  };
  closeQuestion = () => {
    let nextState = this.state;
    nextState["questionOn"] = false;
    this.setState(nextState);
    this.props.offMobileChat();
    this.chatBtn.style.display = "block";
  };

  handleCheckInDate = (e) => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    nextState["checkInDate"] = date;
    nextState["checkInCalendar"] = false;

    this.setState(nextState);
  };

  handleCheckOutDate = (e) => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    nextState["checkOutDate"] = date;
    nextState["checkOutCalendar"] = false;

    this.setState(nextState);
  };

  checkInCalendarView = () => {
    const { checkInCalendar } = this.state;
    let nextState = this.state;
    if (checkInCalendar === false) {
      nextState["checkInCalendar"] = true;
    } else {
      nextState["checkInCalendar"] = false;
    }
    this.setState(nextState);
  };

  checkOutCalendarView = () => {
    const { checkOutCalendar } = this.state;
    let nextState = this.state;
    if (checkOutCalendar === false) {
      nextState["checkOutCalendar"] = true;
    } else {
      nextState["checkOutCalendar"] = false;
    }
    this.setState(nextState);
  };
  // questionView

  // closeQuestion
  phoneCall = (e, tel) => {
    e.preventDefault();
    window.ReactNativeWebView.postMessage("telSend|" + tel);
  };

  render() {
    const { states, t } = this.props;
    if (this.state.isLoading) return null;
    return (
      <div className={cx("fixed-info")}>
        <div className={cx("tag-box")}>
          {states.house["AD_HOUSE_YN"] === "Y" && (
            <button className={cx("nomination-btn")}>
              {t("inform.room.recommend")}
            </button>
          )}
          <button className={cx("primary-blue-btn")}>
            {states.house["RENT_TYPE_NAME"]}
          </button>
          <span> {states.house["HOUSE_TYPE"]}</span>
          {states.house["VR_HOUSE_YN"] === "Y" && (
          <button className={cx("primary-vr-btn")} onClick={() => window.ReactNativeWebView.postMessage("open|" + states.house["VR_URL"])}>
          <img src={vr_see} alt="vr-see" />
            {t("inform.vr")}
          </button>
          )}
        </div>
        <h1>
          {states.house["HOUSE_NAME"] &&
            states.brand["BRAND_NAME"] &&
            states.brand["BRAND_NAME"] + " " + states.house["HOUSE_NAME"]}
        </h1>

        <div className={cx("util-buttons")}>
          {states.favCheck ? (
            <div
              onClick={(e) => {
                this.props.DelFav(e, states.house.HOUSE_ID);
              }}
            >
              <img src={star_yellow} alt="fav" />
            </div>
          ) : (
            <div
              onClick={(e) => {
                this.props.AddFav(e, states.house.HOUSE_ID);
              }}
            >
              <img src={star_gray} alt="fav" />
            </div>
          )}
          <div
            onClick={() => this.props.AddComparelist(states.house["HOUSE_ID"])}
          >
            <img src={folder_add} alt="folder" className={cx("share")} />
          </div>
          <div>
            <img src={share} alt="share" />
          </div>
          <div className={cx("hover-util")}>
            <button onClick={this.makeShareButton}>
              <img src={kakao_share} alt="kakao_share" />
            </button>

            <button onClick={this.facebookShareButton}>
              <img src={facebook_share} alt="kakao_share" />
            </button>

            <button onClick={this.twitterShareButton}>
              <img src={twitter_share} alt="kakao_share" />
            </button>

            <button onClick={this.normalShareLink}>
              <img src={link_share} alt="kakao_share" />
            </button>
          </div>
          <button onClick={(e) => this.props.openModal(e)}>
            <img src={folder_add} alt="folder_add" />
            {t("inform.comparison")} ({states.compareList.length})
          </button>
        </div>

        <div className={cx("chating", { chatOn: this.state.questionOn })}>
          <div className={cx("chating-head")}>
            <h1>{t("inform.room.question")}</h1>
            <p
              onClick={(e) =>
                this.phoneCall(e, this.props.states.brand.BRAND_TEL_NO)
              }
            >
              {t("inform.room.callQuestion")}{" "}
              {this.props.states.brand.BRAND_TEL_NO}
            </p>
            <div onClick={this.closeQuestion}>+</div>
          </div>
          <div className={cx("date-selector")} id="calendar">
            <span>{t("inform.room.checkInDate")}</span>
            <input
              type="text"
              placeholder={t("inform.room.checkIn")}
              className={cx("start-date")}
              onClick={this.checkInCalendarView}
              value={this.state.checkInDate}
              readOnly
            />
            {this.state.checkInCalendar && (
              <Calendar
                id="calendar"
                className={cx("cal")}
                value={this.state.calendarDate}
                onChange={this.handleCheckInDate}
              />
            )}
            <input
              type="text"
              placeholder={t("inform.room.checkOut")}
              className={cx("end-date")}
              value={this.state.checkOutDate}
              onClick={this.checkOutCalendarView}
              readOnly
            />
            {this.state.checkOutCalendar && (
              <Calendar
                id="calendar"
                className={cx("cal")}
                value={this.state.calendarDate}
                onChange={this.handleCheckOutDate}
              />
            )}
          </div>
          <div className={cx("chat-body")} ref={this.mChatScroll}>
            {this.props.messageList && (
              <>
                {this.props.messageList.map((val, index) => {
                  if (val.ACCOUNT_TYPE === "0003") {
                    return (
                      <div className={cx("user-chat")} key={index}>
                        <div className={cx("chat")}>
                          <span className={cx("chat-content")}>
                            {val.MESSAGE}
                          </span>
                          <span className={cx("time-line")}>
                            {val.MESSAGE_DT}
                          </span>
                        </div>
                        <span className="clear"></span>
                      </div>
                    );
                  } else {
                    return (
                      <div className={cx("admin-chat")} key={index}>
                        <div className={cx("chat-profile")}>
                          <img src={val.MEMBER_PIC_URL} alt="profile" />
                        </div>
                        {val.MEMBER_PIC_URL === "" ? (
                          <div className={cx("chat")}>
                            <span>{val.MESSAGE}</span>
                            <span className={cx("time-line")}>
                              {val.MESSAGE_DT}
                            </span>
                          </div>
                        ) : (
                          <div className={cx("chat2")}>
                            <span>{val.MESSAGE}</span>
                            <span className={cx("time-line")}>
                              {val.MESSAGE_DT}
                            </span>
                          </div>
                        )}
                        <span className="clear"></span>
                      </div>
                    );
                  }
                })}
              </>
            )}
            <div className="clear"></div>
          </div>
          <div className={cx("chat-sender")}>
            <form onSubmit={(e) => this.houseMessageSend(e)}>
              <input
                type="text"
                className={cx("messageInput")}
                placeholder={t("inform.room.plzInsertMessage")}
                onChange={(e) => this.MessageChn(e)}
                value={this.state.InqMessage}
              />
              <button
                type="submit"
                className={cx("icon")}
                // onClick={e => this.houseMessageSend}
              ></button>
            </form>
          </div>
        </div>

        <form>
          <button
            id="roomchating"
            type="button"
            onClick={this.questionView}
            ref={(ref) => {
              this.chatBtn = ref;
            }}
          >
            {t("inform.room.chat")}
          </button>
        </form>

        <Popup
          closeModal={this.closeLoginModal}
          isModalOpen={this.state.isLoginModalOpen}
        >
          <Login
            closeModal={this.closeLoginModal}
            isModalOpen={this.state.isLoginModalOpen}
          />
        </Popup>
      </div>
    );
  }
}

export default withTranslation()(MobileDetailMenu);
