import React from "react";
import Compare from "components/house/compare";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { resCode } from "lib/resCode";

import * as HouseDetailActions from "stores/modules/housedetail";

class CompareContainer extends React.Component {
  state = {
    loading: true,
    comparelist: [],
    listIndex: []
  };

  componentDidMount() {
    let nextState = this.state;

    const Mystorage = localStorage;

    const compareList_arr = JSON.parse(Mystorage.getItem("compared"));

    new Promise((resolve, reject) => {
      let count = 0;
      nextState["comparelist"] = [];

      if (compareList_arr.length <= 0) resolve();

      compareList_arr.forEach(async item => {
        const data = await this.houseDetailSearch(item);

        nextState["comparelist"].push(data);
        count++;

        if (count === compareList_arr.length) resolve();
      });
    }).then(() => {
      nextState["loading"] = false;

      this.setState(nextState);
    });
  }

  houseDetailSearch = async houseId => {
    try {
      const { HouseDetailActions } = this.props;

      const houserest = `/house/${houseId}`;
      const res = await HouseDetailActions.HouseDetail(houserest);

      const data = resCode(res);

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  delList = listIndex => {
    const Mystorage = localStorage;

    let { comparelist } = this.state;

    let compareList_arr = JSON.parse(Mystorage.getItem("compared"));

    compareList_arr.forEach((element, index) => {
      listIndex.forEach(idx => {
        if (index === parseInt(idx)) {
          delete compareList_arr[index];
        }
      });
    });

    let tmp = [];

    compareList_arr.forEach(value => {
      if (value !== null || value !== undefined) {
        tmp.push(value);
      }
    });

    localStorage.setItem("compared", JSON.stringify(tmp));

    comparelist.forEach((element, index) => {
      listIndex.forEach(idx => {
        if (index === parseInt(idx)) {
          delete comparelist[index];
        }
      });
    });

    let newList = [];

    comparelist.forEach(value => {
      if (value !== null || value !== undefined) {
        newList.push(value);
      }
    });

    let nextState = this.state;
    nextState["comparelist"] = newList;

    this.setState(nextState);
  };

  render() {
    if (this.state.loading) return "Loading.....";

    return (
      <Compare
        delList={this.delList}
        comparelist={this.state.comparelist}
        closeModal={this.props.closeModal}
        isModalOpen={this.props.isModalOpen}
      />
    );
  }
}

export default connect(null, dispatch => ({
  HouseDetailActions: bindActionCreators(HouseDetailActions, dispatch)
}))(CompareContainer);
