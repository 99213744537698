import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import styles from "./_Footer.module.scss";
import apple from "assets/images/apple.png";
import google from "assets/images/google_play.png";
import korea from "assets/images/korea.png";
import usa from "assets/images/usa.png";
import facebook from "assets/images/Facebook.png";
import n_blog from "assets/images/n_blog.png";
import instargram from "assets/images/Instagram.png";
import n_post from "assets/images/n_post.png";

import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class Footer extends React.Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <footer className={cx("footer")} id="footer">
        <div className={cx("content")}>
          <div className={cx("introduce")}>
            <div>
              <div className={cx("download")}>
                <a
                  href="https://apps.apple.com/kr/app/%EC%BB%B4%EC%95%A4%EC%8A%A4%ED%85%8C%EC%9D%B4/id1089462100"
                  target="blank"
                >
                  <img src={apple} alt={"download-apple"} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.thecomenstay&hl=ko"
                  target="blank"
                >
                  <img src={google} alt={"download-google"} />
                </a>
              </div>
              <h3>{t("footer.ComeAndStay")}</h3>
              <ul>
                <li>
                  <Link to="/company/intro">{t("footer.intro")}</Link>
                </li>
                <li>
                  <Link to="/company/terms">
                    &nbsp;&nbsp;|&nbsp;&nbsp;{t("footer.terms")}
                  </Link>
                </li>
                <li>
                  <Link to="/company/Privacy">
                    &nbsp;&nbsp;|&nbsp;&nbsp;{t("footer.privacy")}
                  </Link>
                </li>
              </ul>
              <div className={cx("copy")}>
                <p>
                  {t("footer.address")}
                  <br />
                  {t("footer.ceo")}&nbsp;&nbsp;{t("footer.company.code")}
                  <br />
                  {t("footer.email")}
                  <br />
                  COPYRIGHT ⓒ Come&amp;stay Co.Ltd.ALL RIGHTS RESERVED.
                </p>
              </div>
            </div>
          </div>
          <div className={cx("navigation")}>
            <div className={cx("language")}>
              <div>
                <Link
                  to="#"
                  onClick={() => {
                    i18n.changeLanguage("ko-KR");
                  }}
                >
                  <img src={korea} alt="korea" />
                  <span>한국어</span>
                </Link>
              </div>

              <div>
                <Link
                  to="#"
                  onClick={() => {
                    i18n.changeLanguage("en-US");
                  }}
                >
                  <img src={usa} alt="usa" />
                  <span>ENGLISH</span>
                </Link>
              </div>
            </div>
            <div className={cx("sns")}>
              <div>
                <a href="https://www.facebook.com/thecomenstay/">
                  <img src={facebook} alt="facebook" />
                </a>
              </div>

              <div>
                <a href="https://www.instagram.com/comenstay_official/">
                  <img src={instargram} alt="instargram" />
                </a>
              </div>

              <div>
                <a href="http://blog.naver.com/prologue/PrologueList.nhn?blogId=comenstay">
                  <img src={n_blog} alt="n_blog" />
                </a>
              </div>

              <div>
                <a href="http://blog.naver.com/prologue/PrologueList.nhn?blogId=comenstay">
                  <img src={n_post} alt="n_post" />
                </a>
              </div>
            </div>
            <div className={cx("clear")}></div>
            <div className={cx("f_navigation")}>
              <ul>
                <li>
                  <div>
                    <Link to="/article/young">{t("footer.seoulhouse")}</Link>
                    <Link to="/article/young/youngknow">
                      {t("footer.lookforhouse")}
                    </Link>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/article/house">{t("footer.house")}</Link>
                    <Link to="/article/house/comic">{t("footer.comic")}</Link>
                    <Link to="/article/house/story">{t("footer.story")}</Link>
                    <Link to="/article/house/report">{t("footer.report")}</Link>
                  </div>
                </li>
                <li>
                  <div>
                    <Link to="/search">{t("footer.search")}</Link>
                    <Link to="/user/mypage">{t("footer.mypage")}</Link>
                    <a href="http://devb.thecomenstay.com/">
                      {t("footer.buspage")}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
