import React from "react";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";
import copy from "copy-to-clipboard";
import $ from "jquery";

import classnames from "classnames/bind";
import Back from "assets/images/back.png";
// import Dashed from "assets/images/dashed.png";
import heart from "assets/images/heart.png";
import heart_color from "assets/images/heart_color.png";
import comment_image from "assets/images/comment.png";
import link_share from "assets/images/link_share.png";
import user_avata from "assets/images/user-avata.png";
import book_mark from "assets/images/bookmark.png";
import play from "assets/images/play.png";
import color_kakao from "assets/images/color_kakao.png";
import color_facebook from "assets/images/color_facebook.png";
import color_blog from "assets/images/color_blog.png";
import book_mark_color from "assets/images/bookmark_color.png";

import MobilePostDetailMenu from "./MobilePostDetailMenu/MobilePostDetailMenu";
import Comment from "./comment/comment";
import ReplyComment from "./replyComment/replyComment";
// import Recomment from "./recomment/Recomment";

import Popup from "components/common/Popup/Popup";
import Login from "containers/user/login/UserLoginContainer";

import styles from "./_PostDetail.module.scss";
const cx = classnames.bind(styles);

class PostDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentList: [],
      comment_len: "",
      article: [],
      articleList: [],
      cookies: this.props.cookies,
      comment: "",
      commentModi: "",
      RecommentVis: false,
      commentModiView: false,
      visIndex: -1,
      seemyscraps: [],
      nthCard: 0,
      maxCardlen: 2,
      isModalOpen: false
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.props.Seemyscrap().then(value => {
      if (value === undefined || value === null) return null;
      let nextState = this.state;
      nextState["seemyscraps"] = value;
      this.setState(nextState);
    });

    const data = await this.props.ContentDetail();
    this.callComments();
    let nextState = this.state;

    nextState["commentList"] = data["commentList"];
    nextState["article"] = data["article"];
    nextState["comment_len"] = data["commentList"].length;

    this.setState(nextState);

    const article = await this.props.CategoryGet({
      CATEGORY: this.state.article.CATEGORY
    });

    this.setState({ articleList: article.articleList });

    this.originInfoTop = this.info.getBoundingClientRect().top;
    window.addEventListener("scroll", this.onScroll);

    // window.Kakao.cleanup();
    // window.Kakao.init("7948b1ade860cba5b7eb0c9d44af8b2f");

    // this.makeShareButton();

    $(".cs-link").on("click", function(e) {
      e.preventDefault();
      const page = e.target.href;
      window.ReactNativeWebView.postMessage("open|" + page);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    delete this.onScroll;
  }

  openModal = e => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    this.setState({ isModalOpen: true });
  };

  closeModal = e => {
    this.setState({ isModalOpen: false });
  };

  onScroll = e => {
    const scrollTop = ("scroll", e.srcElement.scrollingElement.scrollTop);
    let st = scrollTop + 40;
    let infoTop = this.info.getBoundingClientRect().top - 40;
    // console.log(infoTop);
    if (infoTop <= 0) {
      this.info.style.top = "40px";
      this.info.style.position = "fixed";
    }
    if (st <= this.originInfoTop) {
      this.info.style.top = "40px";
      this.info.style.position = "absolute";
    }
  };

  makeShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    let _housename = this.state.article["TITLE"];
    let _introduce = this.state.article["TITLE"];
    let _houseid = this.state.article["ARTICLE_ID"];
    let _imgurl = this.state.article["INFO_PIC_URL"];
    let _title = this.state.article["TITLE"];

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "kakaoArticleShare",
        title: _title,
        housename: _housename,
        introduce: _introduce,
        imgurl: _imgurl,
        houseid: _houseid,
        page
      })
    );

    // window.Kakao.Link.createDefaultButton({
    //   container: "#kakao",
    //   objectType: "feed",
    //   content: {
    //     title: _housename,
    //     description: _introduce,
    //     imageUrl: _imgurl,
    //     link: {
    //       webUrl: _address,
    //       mobileWebUrl: _address,
    //       androidExecParams: _address,
    //       iosExecParams: _address
    //     }
    //   }
    // });
  };

  facebookShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    page = encodeURIComponent(page);
    document.getElementById("og:url").setAttribute("content", page);
    document
      .getElementById("og:title")
      .setAttribute("content", this.state.article.TITLE);
    document
      .getElementById("og:description")
      .setAttribute("content", this.state.article.TITLE);
    document
      .getElementById("og:image")
      .setAttribute("content", this.state.article.INFO_PIC_URL);

    // window.open(
    //   `https://www.facebook.com/sharer/sharer.php?href=${page}`,
    //   "_blank"
    // );
    window.ReactNativeWebView.postMessage(
      "open|" + `https://www.facebook.com/sharer/sharer.php?href=${page}`
    );
  };

  naverShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    // window.open(
    //   `http://blog.naver.com/openapi/share?url=${page}&title=${this.state.article.TITLE}&content=${page}`,
    //   "_blank"
    // );
    window.ReactNativeWebView.postMessage(
      "open|" +
        `http://blog.naver.com/openapi/share?url=${page}&title=${this.state.article.TITLE}&content=${page}`
    );
  };

  normalShareLink = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    copy(page);
  };

  AddComment = e => {
    const { t } = this.props;
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    e.preventDefault();
    let nextState = this.state;
    // if (this.)
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      // } else if (JSON.stringify(this.props.cookies) === "{}") {
    } else if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
    } else {
      this.props.AddComment(this.state.article.ARTICLE_ID, this.state.comment);
      // this.props.AddComment(this.state.comment);
      // window.location.reload();
      this.props.ContentDetail();
      this.callComments();
      nextState["comment"] = "";
      this.setState(nextState);
    }
  };

  //댓글 삭제
  delComment = (a, b) => {
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    const { t } = this.props;
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      // } else if (JSON.stringify(this.props.cookies) === "{}") {
    } else {
      this.props.DelComment(this.state.article.ARTICLE_ID, b);
      this.props.ContentDetail();
      this.callComments();
    }
  };

  // 댓글 수정
  modiComment = (a, b, c) => {
    const { t } = this.props;
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    // a= house_id b = message , b = seq
    console.log(a, b, c);
    let nextState = this.state;
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      // } else if (JSON.stringify(this.props.cookies) === "{}") {
      // this.openModal();
    } else {
      this.props.ModiComment(a, b, c);
      // this.props.AddComment(this.state.comment);
      // window.location.reload();
      this.props.ContentDetail();
      this.callComments();
      nextState["comment"] = "";
      this.setState(nextState);
    }
  };

  // 스크랩 추가
  AddScraps = async e => {
    e.preventDefault();
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      return;
    }
    await this.props.AddScraps(this.state.article.ARTICLE_ID);
    const data = await this.props.ContentDetail();

    if (!data && data.length <= 0) {
      window.location.reload();
    }

    let nextState = this.state;
    nextState["article"] = data.article;
    this.setState(nextState);
  };

  // 스크랩 삭제
  DeleteScraps = async e => {
    e.preventDefault();
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      return;
    }
    await this.props.DeleteScraps(this.state.article.ARTICLE_ID);
    const data = await this.props.ContentDetail();

    if (!data && data.length <= 0) {
      window.location.reload();
    }

    let nextState = this.state;
    nextState["article"] = data.article;
    this.setState(nextState);
  };

  AddLike = async e => {
    e.preventDefault();
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      return;
    }
    await this.props.AddLike(this.state.article.ARTICLE_ID);
    const data = await this.props.ContentDetail();

    if (!data && data.length <= 0) {
      window.location.reload();
    }

    let nextState = this.state;
    nextState["article"] = data.article;
    this.setState(nextState);
  };

  DeleteLike = async e => {
    e.preventDefault();
    const MyStorage = localStorage;
    const auth = MyStorage.getItem("auth");
    // if (!this.props.isLogined) {
    if (auth === "" || auth === null || auth === undefined) {
      this.openModal();
      return;
    }
    await this.props.DeleteLike(this.state.article.ARTICLE_ID);
    const data = await this.props.ContentDetail();

    if (!data && data.length <= 0) {
      window.location.reload();
    }

    let nextState = this.state;
    nextState["article"] = data.article;
    this.setState(nextState);
  };

  showreComment = (e, index, seq) => {
    e.preventDefault();
    let nextState = this.state;
    nextState["RecommentVis"] = !nextState["RecommentVis"];
    nextState["visIndex"] = index;
    this.setState(nextState);
  };

  onChange = e => {
    let nextState = this.state;
    nextState["comment"] = e.target.value;
    this.setState(nextState);
  };

  // 하우스 상세페이지 펨하우스 슬라이드관련 ( 이전 이미지 )
  prevcard = e => {
    e.preventDefault();
    let nextState = this.state;

    if (nextState["nthCard"] > 0) {
      nextState["nthCard"] = nextState["nthCard"] - 1;
      this.setState(nextState);
    } else {
      nextState["nthCard"] = nextState["maxCardlen"];
      this.setState(nextState);
    }
  };

  //하우스  상세페이지 펨하우스 슬라이드관련 ( 다음 이미지 )
  nextcard = e => {
    e.preventDefault();

    let nextState = this.state;

    if (nextState["nthCard"] < nextState["maxCardlen"]) {
      nextState["nthCard"] = nextState["nthCard"] + 1;
      this.setState(nextState);
    } else {
      nextState["nthCard"] = 0;
      this.setState(nextState);
    }
  };

  // 하우스 상세페이지 슬라이드 관련 현재 펨하우스가 몇번째 인지 확인 및 state 에 저장 ..
  warpCard = index => {
    let nextState = this.state;
    nextState["nthCard"] = index;
    this.setState(nextState);
  };

  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      console.log("/app" + item["ARTICLE_URL"]);
      // this.props.history.push("/app" + item["ARTICLE_URL"]);
      window.location.href = "/app" + item["ARTICLE_URL"];
    } else {
      // window.open(item["ARTICLE_URL"], "_blank");
      window.ReactNativeWebView.postMessage("open|" + item["ARTICLE_URL"]);
    }
  };

  callComments = () => {
    this.props.ContentDetail().then(value => {
      let nextState = this.state;

      nextState["commentList"] = value["commentList"];
      nextState["article"] = value["article"];
      nextState["comment_len"] = value["commentList"].length;
      this.setState(nextState);
    });
  };

  closeRecomment = () => {
    let nextState = this.state;
    nextState["RecommentVis"] = false;
    this.setState(nextState);
  };

  back = () => {
    this.props.history.goBack();
  };

  commentModiChange = e => {
    let nextState = this.state;
    nextState["commentModi"] = e.target.value;
    this.setState(nextState);
  };

  viewCommentModi = () => {
    let nextState = this.state;
    nextState["commentModiView"] = !this.state.commentModiView;
    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={cx("Postdetail-wrap")}>
          {/* <div className={cx("post-top-nav")}>
            <img src={Back} alt="back-to-page" onClick={this.back} />
            <span>{this.state.article["CATEGORY_NAME"]}</span>
          </div> */}
          <div>
            <div className={cx("post-wrap")}>
              <div className={cx("post-content")}>
                {this.state.article.INFO_PIC_URL && (
                  <div className={cx("main-image")}>
                    <img src={this.state.article["INFO_PIC_URL"]} alt="img" />
                  </div>
                )}
                <MobilePostDetailMenu
                  article={this.state.article}
                  AddScraps={this.AddScraps}
                  DeleteScraps={this.DeleteScraps}
                  AddLike={this.AddLike}
                  DeleteLike={this.DeleteLike}
                />
                <div className={cx("main-content")}>
                  {ReactHtmlParser(this.state.article["CONTENTS"])}
                </div>

                <div className={cx("post-comment")}>
                  <div className={cx("comment_count")}>
                    {t("PostDetail.reply")}
                    <span className={cx("primary_blue")}>
                      &nbsp;{this.state.comment_len}
                    </span>
                  </div>
                  <form className={cx("add-comment")}>
                    <input
                      type="text"
                      placeholder={
                        JSON.stringify(this.props.cookies) === "{}"
                          ? t("PostDetail.pleaseLogin")
                          : t("PostDetail.writeReply")
                      }
                      onChange={e => this.onChange(e)}
                      value={this.state.comment}
                    />
                    <button onClick={e => this.AddComment(e)}>
                      {t("PostDetail.confirm")}
                    </button>
                  </form>
                  {this.state.commentList
                    .filter(value => value["IS_REPLY"] === "N")
                    .map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={cx("comments")}>
                            <Comment
                              MESSAGE={item["MESSAGE"]}
                              SEQ={item["SEQ"]}
                              NICK_NAME={item["NICK_NAME"]}
                              CREATE_DT={item["CREATE_DT"]}
                              IS_EDITABLE={item["IS_EDITABLE"]}
                              index={index}
                              cookies={this.props.cookies}
                              AddreComment={this.props.AddComment}
                              ARTICLE_ID={this.state.article.ARTICLE_ID}
                              callComments={this.callComments}
                              ContentDetail={this.props.ContentDetail}
                              closeRecomment={this.closeRecomment}
                              delComment={this.delComment}
                              modiComment={this.modiComment}
                              isLogined={this.props.isLogined}
                            />
                            {this.state.commentList
                              .filter(value => {
                                if (
                                  value["IS_REPLY"] === "Y" &&
                                  value["ORG_SEQ"] === item["ORG_SEQ"]
                                ) {
                                  return (
                                    value["IS_REPLY"] === "Y" &&
                                    value["ORG_SEQ"] === item["ORG_SEQ"]
                                  );
                                }
                                return null;
                              })
                              .map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <ReplyComment
                                      MESSAGE={item["MESSAGE"]}
                                      SEQ={item["SEQ"]}
                                      NICK_NAME={item["NICK_NAME"]}
                                      CREATE_DT={item["CREATE_DT"]}
                                      IS_EDITABLE={item["IS_EDITABLE"]}
                                      index={index}
                                      cookies={this.props.cookies}
                                      AddreComment={this.props.AddComment}
                                      ARTICLE_ID={this.state.article.ARTICLE_ID}
                                      callComments={this.callComments}
                                      ContentDetail={this.props.ContentDetail}
                                      closeRecomment={this.closeRecomment}
                                      delComment={this.delComment}
                                      modiComment={this.modiComment}
                                      isLogined={this.props.isLogined}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>

                {/* 팸 하우스 스토리 */}
                {this.state.articleList.length > 0 && (
                  <div
                    className={cx("house-story")}
                    ref={ref => {
                      this.manager = ref;
                    }}
                  >
                    <h1>
                      {this.state.article.CATEGORY_NAME}{" "}
                      {window.screen.width < 768 && t("PostDetail.moreView")}
                    </h1>
                    <div className={cx("story-cards-slider")}>
                      <div
                        className={cx("story-cards-inner")}
                        data-pos-location={this.state.nthCard}
                      >
                        {this.state.articleList.map((item, index) => {
                          if (index > 3) return null;
                          return (
                            <div
                              key={index}
                              className={cx("story-card")}
                              onClick={e => this.goto(item)}
                            >
                              <div className={cx("card-image")}>
                                {item.ARTICLE_TYPE === "2" && (
                                  <img
                                    className={cx("card-play")}
                                    src={play}
                                    alt="play"
                                  />
                                )}
                                <img src={item.INFO_THUMB_URL} alt="card" />
                              </div>
                              <div className={cx("card-title")}>
                                {item.TITLE}
                              </div>
                              <div className={cx("card-footer")}>
                                <span>{item.CREATE_DT}</span>
                                {item.ARTICLE_TYPE === "1" && (
                                  <>
                                    <span>
                                      <img src={heart} alt="heart" />
                                      {item.LIKE_CNT}
                                    </span>
                                    <span>
                                      <img src={comment_image} alt="comment" />
                                      {item.COMMENT_CNT}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={cx("fixed-info")}
                ref={ref => {
                  this.info = ref;
                }}
              >
                <h3 className={cx("primary_blue")}>
                  {this.state.article["CATEGORY_NAME"]}
                </h3>
                <h1>{this.state.article["TITLE"]}</h1>

                {this.state.article["WRITER"] && (
                  <div className={cx("publisher")}>
                    <span className={cx("profile")}>
                      <img src={user_avata} alt="user-avata" />
                    </span>
                    {this.state.article["WRITER"]}
                    <span className={cx("write-date")}>
                      {this.state.article["CREATE_DT"]}
                    </span>
                  </div>
                )}

                <div className={cx("util-buttons")}>
                  <div onClick={this.makeShareButton}>
                    <img src={color_kakao} alt="icon" />
                  </div>
                  <div onClick={this.facebookShareButton}>
                    <img src={color_facebook} alt="icon" />
                  </div>
                  <div onClick={this.naverShareButton}>
                    <img src={color_blog} alt="icon" />
                  </div>
                  <div onClick={this.normalShareLink}>
                    <img src={link_share} alt="icon" />
                  </div>
                </div>
                {this.state.article.IS_LIKE_YN === "Y" ? (
                  <button className={cx("like")} onClick={this.DeleteLike}>
                    <img src={heart_color} alt="heart" /> {t("PostDetail.like")}
                    &nbsp;
                    {this.state.article["LIKE_CNT"]}
                  </button>
                ) : (
                  <button className={cx("like")} onClick={this.AddLike}>
                    <img src={heart} alt="heart" /> {t("PostDetail.like")}&nbsp;
                    {this.state.article["LIKE_CNT"]}
                  </button>
                )}

                {this.state.article.IS_SCRAP_YN === "Y" ? (
                  <button className={cx("scrap")} onClick={this.DeleteScraps}>
                    <img src={book_mark_color} alt="icon" />{" "}
                    {t("PostDetail.scrap")}&nbsp;
                  </button>
                ) : (
                  <button className={cx("scrap")} onClick={this.AddScraps}>
                    <img src={book_mark} alt="icon" /> {t("PostDetail.scrap")}
                    &nbsp;
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
        >
          <Login
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          />
        </Popup>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PostDetail));
