import React from "react";
import { withRouter } from "react-router-dom";
import { resCode } from "lib/resCode";
import { withTranslation } from "react-i18next";
import { withCookies } from "react-cookie";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/user";

import Mypage from "components/user/mypage";
import Recently from "components/user/mypage/details/Recently";
import Scraps from "components/user/mypage/details/Scraps";
import Inquiry from "containers/user/inquiry/inquiryContainer";
import UserAccount from "components/user/mypage/details/UserAccount";

import Popup from "components/common/Popup";
import Login from "containers/user/login/UserLoginContainer";

class MypageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberData: "",
      NationCodes: [],
      viewdhouse_arr: [],
      viewdhouse_len: "",
      isLoading: true,
      isModalOpen: false
    };
    this.isTemp = false;
  }

  componentDidMount = async () => {
    try {
      const { t } = this.props;
      window.ReactNativeWebView.postMessage(
        "onChangeTitle|" + t("footer.mypage")
      );
    } catch (error) {}
    
    await this.ViewdHouse(); // 최근 본 하우스

    //로그인된 회원이라면
    if (this.props.isLogined) {
      // let token = this.props.cookies.get("auth");

      // window.ReactNativeWebView.postMessage("cookie|" + token);
      await this.Myinfo(); // 회원의 정보를 가져옴
      await this.Nation_Codes(); // 사용할 국가코드를 받아옴
    }

    let nextState = this.state;
    nextState["isLoading"] = false;
    this.setState(nextState);
  };

  openModal = () => {
    this.isTemp = true;
    this.setState({ isModalOpen: true });
  };

  closeModal = e => {
    this.setState({ isModalOpen: false });
  };

  // 최근본 하우스
  ViewdHouse = async () => {
    const { UserActions } = this.props;
    const myStorage = window.localStorage;
    const viewdhouse = myStorage.getItem("viewdhouse");
    try {
      const res = await UserActions.ViewdHouse(viewdhouse);

      const data = resCode(res);

      let nextState = this.state;
      nextState["viewdhouse_arr"] = data.houseList;
      nextState["viewdhouse_len"] = data.houseList.length;
      return this.setState(nextState);
    } catch (e) {
      console.log(e);
    }
  };

  // 스크랩보기
  SeeScraps = async () => {
    const { UserActions } = this.props;
    try {
      const res = await UserActions.SeeScraps();

      const data = resCode(res);

      return data.articleList;
    } catch (e) {
      console.log(e);
    }
  };

  // 스크랩삭제
  DelScraps = async houseId => {
    const { UserActions } = this.props;
    try {
      const res = await UserActions.DelScraps(houseId);

      return res.data.result.data.articleList;
    } catch (e) {
      console.log(e);
    }
  };

  // 마이페이지 계정정보 카테고리에서 사용 할 국가코드불러옴 ...
  Nation_Codes = async () => {
    try {
      const res = await this.props.UserActions.Nation_Codes();
      const data = resCode(res);

      const nationList = data.nationList;
      let nextState = this.state;
      nextState["NationCodes"] = nationList;
      this.setState(nextState);
    } catch (e) {
      console.log(e);
    }
  };

  // 나의 회원정보 받아옴
  Myinfo = async () => {
    try {
      const res = await this.props.UserActions.Myinfo(); // 회원정보를 포함한 api 통신 내용 받아 변수에 넣음
      const data = resCode(res);

      const NextMemberData = data.member; // 회원정보만 빼서 받아옴 ..
      let nextState = this.state; // 현재 state 동기식으로 변경
      nextState["MemberData"] = NextMemberData;
      this.setState(nextState);
    } catch (e) {
      // 통신중 에러가 있다면
      console.log(e);
    }
  };

  //나의 회원정보 수정
  MyinfoModify = async ({
    EMAIL,
    MEMBER_NAME,
    GENDER,
    BIRTHYEAR,
    NATION_CODE,
    NEW_PASSWORD,
    NOTICE_YN,
    NEWS_NOTICE_YN
  }) => {
    const { UserActions } = this.props;
    const { t } = this.props;
    try {
      const res = await UserActions.MyinfoModify({
        EMAIL,
        MEMBER_NAME,
        GENDER,
        BIRTHYEAR,
        NATION_CODE,
        NEW_PASSWORD,
        NOTICE_YN,
        NEWS_NOTICE_YN
      });

      if(res.data.result.resCode === "0000"){
        this.Myinfo();
        window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.infoModiDone"));
        return;
      }else {
        window.ReactNativeWebView.postMessage("sendAlert|" + res.data.result.resMessage);
        return;
      }
      // alert(t("myPage.infoModiDone"));
    } catch (e) {
      console.log(e);
    }
  };

  SeeFavHouse = async () => {
    const { UserActions } = this.props;
    try {
      const res = await UserActions.SeeFavHouse();
      const data = resCode(res);

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  AddFavHouse = async houseid => {
    const { UserActions } = this.props;
    try {
      const res = await UserActions.AddFavHouse(houseid);
      console.log(res);
      // return resCode(res);
    } catch (e) {
    } finally {
      await this.ViewdHouse();
    }
  };

  deleteFavHouse = async houseid => {
    const { UserActions } = this.props;
    try {
      const res = await UserActions.delFavHouse(houseid);
      console.dir(res);

      return resCode(res);
    } catch (e) {
      console.log(e);
    } finally {
      await this.ViewdHouse();
    }
  };

  render() {
    if (this.state.isLoading) return null;

    let page = this.props.match.params.type;
    // 로그인이 안되어있을때 처리
    if (!this.props.isLogined) {
      if (
        page === "interest" ||
        page === "scraps" ||
        page === "inquiry" ||
        page === "privacy"
      ) {
        page = "recent";
        if (!this.state.isModalOpen && !this.isTemp) this.openModal();
        else if (!this.state.isModalOpen && this.isTemp) this.isTemp = false;
      }
    }

    return (
      <Mypage
        viewdhouse_arr={this.state.viewdhouse_arr}
        viewdhouse_len={this.state.viewdhouse_len}
        thispage={page || "recent"}
        isLogined={this.props.isLogined}
        Myinfo={this.Myinfo}
        MemberData={this.state.MemberData}
        NationCodes={this.state.NationCodes}
        SeeFavHouse={this.SeeFavHouse}
        SeeScraps={this.SeeScraps}
        AddFavHouse={this.AddFavHouse}
        deleteFavHouse={this.deleteFavHouse}
      >
        {!page && (
          <Recently
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            openModal={this.openModal}
            closeModal ={this.closeModal}
            thispage={"recent"}
            isLogined={this.props.isLogined}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            AddFavHouse={this.AddFavHouse}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        {page === "recent" && (
          <Recently
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            openModal={this.openModal}
            closeModal ={this.closeModal}
            thispage={"recent"}
            isLogined={this.props.isLogined}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            AddFavHouse={this.AddFavHouse}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        {page === "interest" && (
          <Recently
            thispage={"interest"}
            deleteFav={this.deleteFav}
            isLogined={this.props.isLogined}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            AddFavHouse={this.AddFavHouse}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        {page === "scraps" && (
          <Scraps
            isLogined={this.props.isLogined}
            thispage={"scraps"}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            DelScraps={this.DelScraps}
            AddFavHouse={this.AddFavHouse}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        {page === "inquiry" && (
          <Inquiry
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            thispage={"inquiry"}
            isLogined={this.props.isLogined}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        {page === "privacy" && (
          <UserAccount
            MyinfoModify={this.MyinfoModify}
            viewdhouse_arr={this.state.viewdhouse_arr}
            viewdhouse_len={this.state.viewdhouse_len}
            thispage={"privacy"}
            isLogined={this.props.isLogined}
            Myinfo={this.Myinfo}
            MemberData={this.state.MemberData}
            NationCodes={this.state.NationCodes}
            SeeFavHouse={this.SeeFavHouse}
            SeeScraps={this.SeeScraps}
            deleteFavHouse={this.deleteFavHouse}
          />
        )}
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
        >
          <Login
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          />
        </Popup>
      </Mypage>
    );
  }
}

export default withTranslation()(
  withCookies(
    connect(
      state => ({
        isLogined: state.user.auth.user.isLogined
      }),
      dispatch => ({
        UserActions: bindActionCreators(UserActions, dispatch),
      })
    )(withRouter(MypageContainer))
  )
);