import React, { createRef } from "react";
// import { Link } from "react-router-dom";
// import Calendar from "react-calendar";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";

import house_icon from "assets/images/house_icon.png";
// import chat_profile from "assets/images/chat-profile.png";
import star from "assets/images/star_yellow.png";
import call from "assets/images/call.png";

import styled from "./_InquiryDetail.module.scss";
// import Mypage from "components/user/mypage/Mypage";
const cx = classnames.bind(styled);

class InquiryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
    this.chatBody = createRef();
    this.state = {
      inquiry: [],
      house: [],
      messageList: [],
      checkInCalendar: false,
      checkOutCalendar: false,
      calendarDate: "",
      checkInDate: "",
      checkOutDate: "",
      InqMessage: "",
      telNo: ""
    };
  }
  componentDidMount() {
    this.get_detail_info();
    this.getMessageList();
    const options = {
      center: new window.kakao.maps.LatLng(
        this.state.house.LATITUDE,
        this.state.house.LONGITUDE
      ),
      level: 3,
      position: new window.kakao.maps.LatLng(
        this.state.house.LATITUDE,
        this.state.house.LONGITUDE
      )
    };
    this.map = new window.kakao.maps.Map(this.mapRef, options);

    new window.kakao.maps.Marker({
      map: this.map,
      position: new window.kakao.maps.LatLng(
        this.state.house.LATITUDE,
        this.state.house.LONGITUDE
      ),
      title: this.state.house["HOUSE_NAME"],
      image: new window.kakao.maps.MarkerImage(
        house_icon,
        new window.kakao.maps.Size(32, 38)
      ),
      zIndex: 50
    });

    // if (window.screen.width < 768) {
    //   document.getElementById("m_footer").style.display = "none";
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messageList.length !== this.props.messageList) {
      if (this.chatBody) {
        this.chatBody.scrollTo(0, this.chatBody.scrollHeight);
      }
    }
  }

  get_detail_info = () => {
    let nextState = this.state;
    nextState["inquiry"] = this.props.inquiry;
    if (this.props.inquiry.BRAND_TEL_NO) {
      nextState["telNo"] = this.props.inquiry.BRAND_TEL_NO;
    }
    nextState["house"] = this.props.house;
    this.setState(nextState);
  };
  getMessageList = () => {
    let nextState = this.state;
    nextState["messageList"] = this.props.messageList;
    this.setState(nextState, () => {
      this.chatBody.scroll(0, this.chatBody.scrollHeight);
    });
  };
  //지도 생성 및 객체 리턴
  checkInCalendarView = () => {
    const { checkInCalendar } = this.state;
    let nextState = this.state;
    if (checkInCalendar === false) {
      nextState["checkInCalendar"] = true;
    } else {
      nextState["checkInCalendar"] = false;
    }
    this.setState(nextState);
  };
  checkOutCalendarView = () => {
    const { checkOutCalendar } = this.state;
    let nextState = this.state;
    if (checkOutCalendar === false) {
      nextState["checkOutCalendar"] = true;
    } else {
      nextState["checkOutCalendar"] = false;
    }
    this.setState(nextState);
  };
  handleCheckInDate = e => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    nextState["checkInDate"] = date;
    nextState["checkInCalendar"] = false;

    this.setState(nextState);
  };
  handleCheckOutDate = e => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    nextState["checkOutDate"] = date;
    nextState["checkOutCalendar"] = false;

    this.setState(nextState);
  };
  MessageChn = e => {
    let NextState = this.state;
    NextState["InqMessage"] = e.target.value;
    this.setState(NextState);
  };

  messageSend = async e => {
    e.preventDefault();
    await this.props.HouseInquery(
      this.props.house.HOUSE_ID,
      this.state.InqMessage
    );
    let nextState = this.state;
    nextState["InqMessage"] = "";
    this.setState(nextState, () => {
      this.chatBody.scroll(0, this.chatBody.scrollHeight);
    });
  };
  moveDetail = () => {
    window.open(this.state.house.HOUSE_URL, "_blank");
  };
  telSend = (e, tel) => {
    e.preventDefault();
    window.ReactNativeWebView.postMessage(
      "telSend|" + tel
    );
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className={cx("inquiryDetail-wrap")}>
          <div>
            <h1>
              {t("myPage.help")} /{" "}
              <span>
                {this.state.house.BRAND_NAME +
                  " " +
                  this.state.house.HOUSE_NAME}
              </span>
            </h1>
          </div>
          <div className={cx("Detail-left")}>
            <div className={cx("map")} ref={ref => (this.mapRef = ref)}>
              {/* <img src={map} alt="지도" /> */}
            </div>
            <div className={cx("info-card")}>
              <div className={cx("result-item")} onClick={this.moveDetail}>
                <div className={cx("item-img")}>
                  <img
                    src={this.state.house.INFO_THUMB_URL}
                    alt="room"
                    className={cx("house_thumb")}
                  />

                  {this.state.house.IS_FAVORITED_YN === "Y" && (
                    <img src={star} alt="star" className={cx("star")} />
                  )}
                </div>
                <div className={cx("item-content")}>
                  <div className={cx("types")}>
                    <button className={cx("primary-blue-btn")}>
                      {this.state.house.RENT_TYPE_NAME}
                    </button>
                    <span>{this.state.house.HOUSE_TYPE_NAME}</span>
                  </div>
                  <span className={cx("item-title")}>
                    {this.state.house.BRAND_NAME +
                      " " +
                      this.state.house.HOUSE_NAME}
                  </span>
                  <span className={cx("item-location")}>
                    {this.state.house.ADDRESS}
                  </span>
                  <div className={cx("item-price")}>
                    <p>
                      <span>{t("myPage.monthPay")}</span>
                      <span>{this.state.house.RENTFEE}</span>
                    </p>
                    <p>
                      <span>{t("myPage.deposit")}</span>
                      <span>{this.state.house.DEPOSIT}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={cx("phonecall")}>
                <img src={call} alt="call" />
                <p>
                  <span className={cx("primary-blue")}>
                    {t("myPage.callCenter")}{" "}
                    <b>
                      {this.state.telNo !== "" ? (
                        <a
                          href={"tel:" + this.state.telNo}
                          onClick={e => {
                            this.telSend(e, this.state.telNo);
                          }}
                        >
                          {this.state.telNo}
                        </a>
                      ) : (
                        "미제공"
                      )}
                    </b>
                  </span>
                </p>
              </div>
              <span>{t("myPage.requestText")}</span>
            </div>
          </div>
          <div className={cx("Detail-right")}>
            <div className={cx("chating")}>
              <div className={cx("date-selector")}>
                <div>
                  <span className={cx("room-title")}>{t("myPage.inRoom")}</span>
                  <span className={cx("room")}>
                    {this.state.inquiry.ROOM_NAME === undefined ||
                    this.state.inquiry.ROOM_NAME === ""
                      ? null
                      : this.state.inquiry.ROOM_NAME}
                  </span>
                </div>
                <div className={cx("day")}>
                  <span className={cx("date-title")}>
                    {t("myPage.checkInDate")}
                  </span>
                  <span>
                    {this.state.inquiry.FROM_DATE === undefined ||
                    this.state.inquiry.FROM_DATE === ""
                      ? null
                      : this.state.inquiry.FROM_DATE}
                  </span>
                  <span className={cx("middle")}>~</span>
                  <span>
                    {this.state.inquiry.TO_DATE === undefined ||
                    this.state.inquiry.TO_DATE === ""
                      ? null
                      : this.state.inquiry.TO_DATE}
                  </span>
                </div>
              </div>
              <div
                className={cx("chat-body")}
                ref={ref => {
                  this.chatBody = ref;
                }}
              >
                {this.props.messageList.length > 0 &&
                  this.props.messageList.map((val, index) => {
                    let date = val.MESSAGE_DT;

                    if (val.ACCOUNT_TYPE === "0003") {
                      return (
                        <div className={cx("user-chat")} key={index}>
                          <div className={cx("chat")}>
                            <span>{val.MESSAGE}</span>
                            <span className={cx("time-line")}>{date}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className={cx("admin-chat")} key={index}>
                          <div className={cx("chat-profile")}>
                            <img src={val.MEMBER_PIC_URL} alt="profile" />
                          </div>
                          <div className={cx("chat")}>
                            <span>{val.MESSAGE}</span>
                            <span className={cx("time-line")}>{date}</span>
                          </div>

                          <span className="clear"></span>
                        </div>
                      );
                    }
                  })}
              </div>
              <div className={cx("chat-sender")}>
                <form onSubmit={this.messageSend}>
                  <input
                    type="text"
                    placeholder={t("myPage.messagePlz")}
                    value={this.state.InqMessage}
                    onChange={e => this.MessageChn(e)}
                  />
                </form>
                <div className={cx("icon")} onClick={this.messageSend}></div>
              </div>
            </div>
          </div>
          <div className={cx("clear")}></div>
        </div>
      </>
    );
  }
}

export default withTranslation()(InquiryDetail);
