import React, { createRef } from "react";
import { resCode } from "lib/resCode";
import { withCookies } from "react-cookie";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/user";
import * as HouseAction from "stores/modules/housedetail";
import { withTranslation } from "react-i18next";

import HouseDetail from "components/house/detail/Detail";
import DetailSkeleton from "components/house/detail/Skeleton/DetailSkeleton";

class HouseContainer extends React.Component {
  constructor(props) {
    super(props);
    this.stompClient = undefined;
    this.stomp = createRef();
    this.state = {
      isLoading: true,
      comparehouselist: [],
      houseDetail: [],
      messageList: [],
      fromDate: "",
      toDate: "",
      favList: [],
      INQUIRY_ID: "",
      dataResult: false,
      resultMsg: ""
    };
  }

  componentDidMount = async () => {
    // await this.Viewcomparelist();
    await this.HouseDetail();

    if (this.props.isLogined) {
      await this.getFavHouseList();
      await this.houseMessageList();
      await this.topic();
    } else {
      this.setState({ isLoading: false });
    }
  };

  topic = () => {
    const topicId = this.state.INQUIRY_ID; // 하우스정보 또는 나의입주문의 상세정보의 INQUIRY_ID값을 사용
    if (topicId) {
      let socket = new window.SockJS("/chat");
      this.stompClient = window.Stomp.over(socket);
      this.stompClient.debug = null; //디버그 메시지 출력 제거, 배포할때 처리
      this.stompClient.connect({}, frame => {
        this.setState({ isLoading: false });
        this.stompClient.subscribe("/topic/" + topicId, greeting => {
          if (JSON.parse(greeting.body) !== undefined || null) {
            let nextState = this.state;
            nextState["messageList"].push(JSON.parse(greeting.body));
            this.setState(nextState);
          }
        });
      });
    }
  };

  // 비교함 목록 뿌려주는 함수
  // Viewcomparelist = () => {
  //   const Mystorage = localStorage;
  //   const { HouseAction } = this.props;

  //   const arr = Mystorage.getItem("compared");

  // JSON.parse(Mystorage.getItem("compared")).map(async item => {
  //   try {
  //     const houserest = `/house/${item}`;

  //     const res = await HouseAction.HouseDetail(houserest);

  //     let nextState = this.state;
  //     nextState["comparehouselist"] = res.data.result.data.comparehouselist;

  //     this.setState(nextState);
  //     return;
  //   } catch (e) {
  //     console.log(e);
  //     this.props.history.push("/error/506");
  //   }
  // });
  // };

  // 비교함 추가 함수
  AddCompare = async houseid => {
    const { t } = this.props;
    const Mystorage = localStorage;
    var compareList_arr = JSON.parse(Mystorage.getItem("compared"));
    var HasSameValue = false;
    if (compareList_arr.length > 3) {
      // alert(t("search.compareAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("search.compareAlert"));
    } else {
      if (compareList_arr.indexOf(houseid) !== -1) {
        HasSameValue = true;
        // alert(t("search.compareAlreadyInAlert"));
        window.ReactNativeWebView.postMessage("sendAlert|" + t("search.compareAlreadyInAlert"));
        return;
      }
      if (!HasSameValue) {
        compareList_arr.push(houseid);
        Mystorage.setItem("compared", JSON.stringify(compareList_arr));
        // alert(t("search.compareInAlert"));
        window.ReactNativeWebView.postMessage("sendAlert|" + t("search.compareInAlert"));
      }
    }
  };

  //하우스 상세정보 뿌려주는 함수
  HouseDetail = async () => {
    const { HouseAction } = this.props;
    try {
      const url = this.props.location.pathname;
      let houserest = url.split("/app");
      houserest = houserest[1];
      const res = await HouseAction.HouseDetail(houserest);

      const data = resCode(res);

      let nextState = this.state;
      if (res.data.result.resCode !== "0000") {
        nextState["dataResult"] = true;
        nextState["resultMsg"] = "정보가 존재하지 않는 페이지 입니다.";
      }
      nextState["houseDetail"] = data;
      if (data.inquiry !== undefined)
        nextState["INQUIRY_ID"] = data.inquiry.INQUIRY_ID;
      this.setState(nextState);
      return data;
    } catch (e) {
      console.log(e);
      this.props.history.push("/app/error/506");
    }
  };

  //하우스 상세 - 입주문의 메세지 보내는 함수
  HouseInquery = async ({
    HOUSE_ID,
    MESSAGE,
    INQUIRY_ID,
    FROM_DATE,
    TO_DATE,
    ROOM_CODE
  }) => {
    const { HouseAction } = this.props;
    try {
      const res = await HouseAction.HouseInquery({
        HOUSE_ID,
        MESSAGE,
        INQUIRY_ID,
        TO_DATE,
        FROM_DATE,
        ROOM_CODE
      });
      if (res.data.result.resCode === "0011") {
        alert(res.data.result.resMessage);
        this.props.history.push("/user/mypage/privacy");
        return;
      }

      return resCode(res);
    } catch (e) {
      console.log(e);
      this.props.history.push("/app/error/506");
    }
  };

  // 하우스 메세지 받아오기
  houseMessageList = async inquiryId => {
    const INQUIRY_ID = this.state.houseDetail.inquiry.INQUIRY_ID;

    if (INQUIRY_ID) {
      const { HouseAction } = this.props;
      try {
        if (inquiryId && inquiryId !== "") {
        } else {
          const res = await HouseAction.HouseDetailMessage({ INQUIRY_ID });
          let nextState = this.state;
          nextState["messageList"] = res.data.result.data.messageList || [];
          if (res.data.result.data.inquiry) {
            nextState["fromDate"] = res.data.result.data.inquiry.FROM_DATE;
            nextState["toDate"] = res.data.result.data.inquiry.TO_DATE;
          }
          this.setState(nextState);
        }
      } catch (e) {
        console.log(e);
        this.props.history.push("/app/error/506");
      }
    }
  };

  // 관심 하우스 추가
  AddFavHouse = async houseid => {
    const { HouseAction } = this.props;

    try {
      const res = await HouseAction.AddFavHouse(houseid);

      const data = resCode(res);

      return data;
    } catch (e) {
      console.dir(e);
    }
  };

  // 관심 하우스 제거
  delFavHouse = async houseid => {
    const { HouseAction } = this.props;
    try {
      const res = await HouseAction.delFavHouse(houseid);
      const data = resCode(res);

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  // 관심 하우스 받아오기

  getFavHouseList = async () => {
    const { HouseAction } = this.props;
    let nextState = this.state;
    try {
      const res = await HouseAction.favHouseList();
      nextState["favList"] = res.data.result.data.houseList;
      this.setState(nextState);
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    if (this.state.dataResult) return this.state.resultMsg;
    if (this.state.isLoading) return <DetailSkeleton />;

    return (
      <>
        <HouseDetail
          isLogined={this.props.isLogined}
          houseDetail={this.state.houseDetail}
          getHouseDetail={this.HouseDetail}
          HouseInquery={this.HouseInquery}
          AddCompare={this.AddCompare}
          comparehouselist={this.state.comparehouselist}
          messageList={this.state.messageList}
          houseMessageList={this.houseMessageList}
          AddFavHouse={this.AddFavHouse}
          delFavHouse={this.delFavHouse}
          favList={this.state.favList}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
        />
      </>
    );
  }
}

export default withTranslation()(
  withCookies(
    connect(
      state => ({
        isLogined: state.user.auth.user.isLogined
      }),
      dispatch => ({
        UserAction: bindActionCreators(UserAction, dispatch),
        HouseAction: bindActionCreators(HouseAction, dispatch)
      })
    )(HouseContainer)
  )
);
