import React from "react";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserAction from "stores/modules/inquery";
import Inquiry from "components/user/mypage/details/Inquiry";

class InquiryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 입주문의 목록 받아오는 함수
  InquiryData = async () => {
    const { UserAction } = this.props;
    try {
      const res = await UserAction.ArticleContent();

      return res.data.result.data;
    } catch (e) {
      console.log(e);
    }
  };
  inquery_del = async (inquiry_id) => {
    const {UserAction} = this.props;
    try{
      const res = await UserAction.inquery_delete(inquiry_id);
      console.log(res);
    }catch(e){
      console.dir(e);
    }
  }

  render() {
    return <Inquiry InquiryData={this.InquiryData} inquery_del={this.inquery_del} />;
  }
}

export default withCookies(
  connect(null, dispatch => ({
    UserAction: bindActionCreators(UserAction, dispatch)
  }))(withRouter(InquiryContainer))
);
