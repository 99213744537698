import React from "react";
// import { Cookies } from "react-cookie";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next"; 
import styles from "./PasswordEmailCheck.scss";

import logo from "assets/images/logo.png";

const cx = classnames.bind(styles);

class PasswordEmailCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmId: "",
      newPw: "",
      newPw2: ""
    };
  }
  componentDidMount() {
  }

  pwChange = e => {
    let nextState = this.state;
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
    // console.log(this.state);
  };
  empty = str => {
    if (str.trim() === "") {
      return true;
    } else {
      return false;
    }
  };

  

  pwSubmit = () => {
    const {t} = this.props;
    // var pattern1 = /[0-9]/;

    // var pattern2 = /[a-zA-Z]/;

    // var pattern3 = /[~!@#$%<>^&*]/;

    // if (this.state.newPw.length < 8) {
    //   alert(t("myPage.pwLengthAlert"));
    //   return false;
    // } else if (
    //   !pattern1.test(this.state.newPw) ||
    //   !pattern2.test(this.state.newPw) ||
    //   !pattern3.test(this.state.newPw)
    // ) {
    //   alert("비밀번호는 영문+숫자+특수기호 조합으로 구성하여야합니다.");
    //   return false;
    // } else {
    //   var SamePass_0 = 0; //동일문자 카운트
    //   var SamePass_1 = 0; //연속성(+) 카운드
    //   var SamePass_2 = 0; //연속성(-) 카운드

    //   var chr_pass_0;
    //   var chr_pass_1;

    //   for (var i = 0; i < this.state.newPw.length; i++) {
    //     chr_pass_0 = this.state.newPw.charAt(i);
    //     chr_pass_1 = this.state.newPw.charAt(i + 1);

    //     //동일문자 카운트
    //     if (chr_pass_0 === chr_pass_1) {
    //       SamePass_0 = SamePass_0 + 1;
    //     } // if

    //     //연속성(+) 카운드
    //     if (chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) === 1) {
    //       SamePass_1 = SamePass_1 + 1;
    //     } // if

    //     //연속성(-) 카운드
    //     if (chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) === -1) {
    //       SamePass_2 = SamePass_2 + 1;
    //     } // if
    //   } // for

    //   if (SamePass_0 > 1) {
    //     alert("동일문자를 3번 이상 사용할 수 없습니다.");
    //     return false;
    //   } // if

    //   if (SamePass_1 > 1 || SamePass_2 > 1) {
    //     alert(
    //       "연속된 문자열(123 또는 321, abc, cba 등)을\n 3자 이상 사용 할 수 없습니다."
    //     );
    //     return false;
    //   } // if
    // }
    if (!this.state.newPw) {
      // alert(t("myPage.newPwAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.newPwAlert"));
      return false;
    } else if (this.state.newPw.length < 8) {
      // alert(t("myPage.pwLengthAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.pwLengthAlert"));
      return false;
    } else if (!this.state.newPw2) {
      // alert(t("myPage.pwCheckAlert"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.pwCheckAlert"));
      return false;
    } else if (this.state.newPw !== this.state.newPw2) {
      // alert(t("myPage.pwNotEqual"));
      window.ReactNativeWebView.postMessage("sendAlert|" + t("myPage.pwNotEqual"));
      return false;
    }

    this.props.pwdModiEmail(this.state.newPw, this.props.confirmId);
  };

  render() {
    const {t} = this.props;
    return (
      <div className={cx("passwordEmailCheck")}>
        <div>
          <div className={cx("textBody")}>
            <h2>{t("myPage.passwordChange")}</h2>
            <h4>{t("myPage.passwordChangeText")}</h4>
          </div>
          <div className={cx("inputWrap")}>
            <div>
              <input
                type="password"
                name="newPw"
                placeholder={t("myPage.newPassword")}
                value={this.state.newPw}
                onChange={this.pwChange}
              />
            </div>
            <div>
              <input
                type="password"
                name="newPw2"
                placeholder={t("myPage.passwordChk")}
                value={this.state.newPw2}
                onChange={this.pwChange}
              />
            </div>
            <div>
              <input
                type="button"
                value={t("myPage.passwordChange")}
                className={cx("submitBtn")}
                onClick={this.pwSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PasswordEmailCheck);
