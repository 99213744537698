import React from "react";
import copy from "copy-to-clipboard";
import { withTranslation } from "react-i18next";
import classnames from "classnames/bind";

import user_avata from "assets/images/user-avata.png";
import book_mark from "assets/images/bookmark.png";
import color_kakao from "assets/images/color_kakao.png";
import color_facebook from "assets/images/color_facebook.png";
import color_blog from "assets/images/color_blog.png";
import heart from "assets/images/heart.png";
import link_share from "assets/images/link_share.png";
import heart_color from "assets/images/heart_color.png";
import book_mark_color from "assets/images/bookmark_color.png";

import styles from "./_MobilePostDetailMenu.module.scss";
const cx = classnames.bind(styles);

class MobilePostDetailMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  makeShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    let _housename = this.props.article["TITLE"];
    let _introduce = this.props.article["TITLE"];
    let _houseid = this.props.article["ARTICLE_ID"];
    let _imgurl = this.props.article["INFO_PIC_URL"];

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "kakaoArticleShare",
        housename: _housename,
        introduce: _introduce,
        imgurl: _imgurl,
        houseid: _houseid,
        page
      })
    );

    // window.Kakao.Link.createDefaultButton({
    //   container: "#kakao",
    //   objectType: "feed",
    //   content: {
    //     title: _housename,
    //     description: _introduce,
    //     imageUrl: _imgurl,
    //     link: {
    //       webUrl: _address,
    //       mobileWebUrl: _address,
    //       androidExecParams: _address,
    //       iosExecParams: _address
    //     }
    //   }
    // });
  };

  facebookShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    page = encodeURIComponent(page);
    document.getElementById("og:url").setAttribute("content", page);
    document
      .getElementById("og:title")
      .setAttribute("content", this.props.article.TITLE);
    document
      .getElementById("og:description")
      .setAttribute("content", this.props.article.TITLE);
    document
      .getElementById("og:image")
      .setAttribute("content", this.props.article.INFO_PIC_URL);

    // window.open(
    //   `https://www.facebook.com/sharer/sharer.php?href=${page}`,
    //   "_blank"
    // );
    window.ReactNativeWebView.postMessage(
      "open|" + `https://www.facebook.com/sharer/sharer.php?href=${page}`
    );
  };

  naverShareButton = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    // window.open(
    //   `http://blog.naver.com/openapi/share?url=${page}&title=${this.state.article.TITLE}&content=${page}`,
    //   "_blank"
    // );
    window.ReactNativeWebView.postMessage(
      "open|" +
        `http://blog.naver.com/openapi/share?url=${page}&title=${this.props.article.TITLE}&content=${page}`
    );
  };

  normalShareLink = () => {
    let hostname = window.location.href;
    let page = hostname.split("/app");
    page = page.join("");
    copy(page);
  };

  addScrap = e => {
    this.props.AddScraps(e);
  };
  delScrap = e => {
    this.props.DeleteScraps(e);
  };
  AddLike = e => {
    this.props.AddLike(e);
  };
  delLike = e => {
    this.props.DeleteLike(e);
  };
  render() {
    const { t } = this.props;
    return (
      <div className={cx("fixed-info")}>
        <h3 className={cx("primary_blue")}>
          {this.props.article["CATEGORY_NAME"]}
        </h3>
        <h1>
          {this.props.article["TITLE"]}
          {/* 바로바로,세탁기 사용 후,조리도구
          <br />
          &nbsp;사용후, 공용공간 사용 후 바로 정리 */}
        </h1>

        {this.props.article["WRITER"] && (
          <div className={cx("publisher")}>
            <span className={cx("profile")}>
              <img src={user_avata} alt="user-avata" />
            </span>
            {this.props.article["WRITER"]}
            <span className={cx("write-date")}>
              {this.props.article["CREATE_DT"]}
            </span>
          </div>
        )}
        {/* <div className={cx("publisher")}> */}

        {/* <span className={cx("profile")}>
            <img src={user_avata} alt="user-avata" />
          </span>
          쉐어하우스 플랜 A
        </div>
        <span className={cx("write-date")}>2019.01.02</span> */}

        <div className={cx("util-buttons")}>
          <div onClick={this.makeShareButton}>
            <img src={color_kakao} alt="icon" />
          </div>
          <div onClick={this.facebookShareButton}>
            <img src={color_facebook} alt="icon" />
          </div>
          <div onClick={this.naverShareButton}>
            <img src={color_blog} alt="icon" />
          </div>
          <div onClick={this.normalShareLink}>
            <img src={link_share} alt="icon" />
          </div>
        </div>
        {this.props.article.IS_LIKE_YN === "Y" ? (
          <button className={cx("like")} onClick={this.delLike}>
            <img src={heart_color} alt="heart" /> {t("encyclop.like")}&nbsp;
            {this.props.article["LIKE_CNT"]}
          </button>
        ) : (
          <button className={cx("like")} onClick={this.AddLike}>
            <img src={heart} alt="heart" /> {t("encyclop.like")}&nbsp;
            {this.props.article["LIKE_CNT"]}
          </button>
        )}

        {this.props.article.IS_SCRAP_YN === "Y" ? (
          <button className={cx("scrap")} onClick={this.delScrap}>
            <img src={book_mark_color} alt="icon" /> {t("report.scrap")}&nbsp;
          </button>
        ) : (
          <button className={cx("scrap")} onClick={this.addScrap}>
            <img src={book_mark} alt="icon" /> {t("report.scrap")}&nbsp;
          </button>
        )}

        {/* <button className={cx("like")}>
          <img src={heart} alt="icon" /> 좋아요&nbsp;{"1"}
        </button>
        <button className={cx("scrap")}>
          <img src={book_mark} alt="icon" /> 스크랩&nbsp;{"1"}
        </button> */}

        <div className={cx("clear")}></div>
      </div>
    );
  }
}

export default withTranslation()(MobilePostDetailMenu);
