import React from "react";
import classnames from "classnames/bind";
import styles from "./_AsideHam.module.scss";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";
import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class AsideHam extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className={
            this.props.isShowHam
              ? cx(["aside-ham", "show"])
              : cx(["aside-ham", "hide"])
          }
        >
          {this.props.memberName !== "" ? (
            <>
              <div className={cx("ham")}>
                <div className={cx("ham-header")}>
                  <h2>
                    <a href="/app">
                      <img src={logo} alt="logo" />
                    </a>
                  </h2>
                </div>
                <div className={cx("ham-body")}>
                  <p>
                    <span>
                      <a href="/app" onClick={e => this.props.Logout(e)}>
                        {t("header.logout")}
                      </a>
                    </span>
                  </p>
                  <p>
                    <Link to="/app/search" onClick={this.props.closeMenu}>
                      {t("header.searchhouse")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/user/mypage" onClick={this.props.closeMenu}>
                      {t("header.nickname")}
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/app/user/mypage/inquiry"
                      onClick={this.props.closeMenu}
                    >
                      {t("header.questionMessage")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/article" onClick={this.props.closeMenu}>
                      {t("header.encyclopedia")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/" onClick={this.props.closeMenu}>
                      {t("header.buspage")}
                    </Link>
                  </p>
                </div>
              </div>
              <div className={cx("aside")} onClick={this.props.closeMenu}></div>
            </>
          ) : (
            <>
              <div className={cx("ham")}>
                <div className={cx("ham-header")}>
                  <h2>
                    <a href="/app">
                      <img src={logo} alt="logo" />
                    </a>
                  </h2>
                </div>
                <div className={cx("ham-body")}>
                  <p>
                    <span>
                      <Link
                        to="/app/user/Register"
                        onClick={this.props.closeMenu}
                      >
                        {t("header.join")}
                      </Link>
                    </span>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <span onClick={this.props.openModal}>
                      {t("header.login")}
                    </span>
                  </p>
                  <p>
                    <Link to="/app/search" onClick={this.props.closeMenu}>
                      {t("header.searchhouse")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/user/mypage" onClick={this.props.closeMenu}>
                      {t("header.nickname")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/user/mypage" onClick={this.props.closeMenu}>
                      {t("header.questionMessage")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app/article" onClick={this.props.closeMenu}>
                      {t("header.encyclopedia")}
                    </Link>
                  </p>
                  <p>
                    <Link to="/app" onClick={this.props.closeMenu}>
                      {t("header.buspage")}
                    </Link>
                  </p>
                </div>
              </div>
              <div className={cx("aside")} onClick={this.props.closeMenu}></div>
            </>
          )}
        </div>
        <div
          className={
            this.props.isShowHam
              ? cx(["ham-back", "back-show"])
              : cx(["ham-back", "back-hide"])
          }
        ></div>
      </>
    );
  }
}

export default withTranslation()(AsideHam);
